import getCleanedPolyline from "./getCleanedPolyline";

//add polyline for history
export default async function addPolyline(
  history,
  mapRef,
  deviceProfile = null
) {
  try {
    //check history length is greater than 0
    if (history && history.length > 0) {
      //create coords array for history.data items using time_created to order

      let coords = history.map((item) => {
        let data = item.data;
        //json parse data
        if (typeof data === "string") {
          data = JSON.parse(data);
        }
        //return coordinates if not empty
        if (data.latitude && data.longitude) {
          if (data.latitude !== 0 && data.longitude !== 0) {
            return [data.longitude, data.latitude];
          } else {
            return null;
          }
        }
        return null;
      });

      //filter out null values
      coords = coords.filter((item) => item !== null);

      let polyLineGeometry = coords;

      //if route_matching_type is not none, get cleaned polyline
      if (
        deviceProfile &&
        deviceProfile.tracker &&
        deviceProfile.tracker.route_matching_type !== "none"
      ) {
        polyLineGeometry = await getCleanedPolyline(
          coords,
          deviceProfile.tracker.route_matching_type
        );
      }

      if (!mapRef.getSource("history")) {
        // add a source for the device's location history
        mapRef.addSource("history", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: [
              {
                type: "Feature",
                geometry: {
                  type: "LineString",
                  coordinates: polyLineGeometry,
                },
              },
            ],
          },
        });

        mapRef.addLayer({
          type: "line",
          source: "history",
          id: "line-background",
          paint: {
            "line-color": "#00D09F",
            "line-width": 3,
            "line-opacity": 1,
          },
        });
      } else {
        //if source already exists, update the source data
        mapRef.getSource("history").setData({
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              geometry: {
                type: "LineString",
                coordinates: polyLineGeometry,
              },
            },
          ],
        });
      }
    }
  } catch (err) {
    console.log(err);
  }
}
