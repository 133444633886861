import React from "react";
import { formatDate } from "../../functions/formatDate";
import { Typography } from "@mui/material";
import { CONFIG } from "../../config";

export const frameColumns = [
  {
    name: "fcnt",
    label: "Frame Counter",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "data",
    label: "Data",
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value) => {
        //input is array
        if (!value) return "N/A";
        if (!value.length) return "N/A";
        //json decode
        let data = JSON.parse(value);

        let string = "";

        //get keys and return key: value pairs
        return Object.keys(data).map((key) => {
          //if value is empty, dont return,

          //if wifimacs, return as string
          if (key === "wifi_macs") {
            //json stringify
            let wifi_macs = JSON.stringify(data[key]);
            //replace the word "mac" with bssid
            wifi_macs = wifi_macs.replace(/mac/g, "bssid");
            return (
              //add to string
              string + key + ": " + wifi_macs + ", "
            );
          }

          if (key === "isLost") {
            return string + key + ": " + (data[key] ? "true" : "false") + ", ";
          }

          if (key === "hotspots") {
            let hotspots = data[key];

            //for each hotspot, show name and rssi
            hotspots = hotspots.map((hotspot) => {
              return (
                string + "Name: " + hotspot.name + " RSSI: " + hotspot.rssi
              );
            });

            return (
              //add to string
              string + key + ": " + hotspots + ", "
            );
          }

          //if dont_log_frame, return nothing
          if (key === "dont_log_frame") {
            return null;
          } else {
            return (
              //add to string
              string + key + ": " + data[key] + ", "
            );
          }
        });
      },
    },
  },
  {
    name: "time_created",
    label: "Time",
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value) => {
        return <Typography>{formatDate(value)}</Typography>;
      },
    },
  },
];

export const brandColumns = [
  {
    name: "id",
    label: "id",
    options: {
      filter: true,
      display: false,
    },
  },
  {
    name: "name",
    label: "Short Name",
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value, tableMeta) => {
        return (
          <Typography
            onClick={() => {
              if (!value) return;
              window.location.href = "/device/brand/" + tableMeta.rowData[0];
            }}
            sx={{
              "&:hover": {
                cursor: "pointer",
                textDecoration: "underline",
              },
            }}
          >
            {value}
          </Typography>
        );
      },
    },
  },
  {
    name: "description",
    label: "Long Name",
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value, tableMeta) => {
        return (
          <Typography
            onClick={() => {
              if (!value) return;
              window.location.href = "/device/brand/" + tableMeta.rowData[0];
            }}
            sx={{
              "&:hover": {
                cursor: "pointer",
                textDecoration: "underline",
              },
            }}
          >
            {value}
          </Typography>
        );
      },
    },
  },
];

export const LNSColumns = [
  {
    name: "id",
    label: "id",
    options: {
      filter: true,
      display: false,
    },
  },
  {
    name: "name",
    label: "Name",
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value, tableMeta) => {
        return (
          <Typography
            onClick={() => {
              if (!value) return;

              window.location.href = "/lns/" + tableMeta.rowData[0];
            }}
            sx={{
              "&:hover": {
                cursor: "pointer",
                textDecoration: "underline",
              },
            }}
          >
            {value}
          </Typography>
        );
      },
    },
  },
  {
    name: "api_route",
    label: "API Route",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "time_created",
    label: "Date Added",
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value) => {
        if (!value) return "N/A";
        return formatDate(value);
      },
    },
  },
  {
    name: "time_updated",
    label: "Date Updated",
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value) => {
        if (!value) return "N/A";
        return formatDate(value);
      },
    },
  },
  {
    name: "active",
    label: "Status",
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value) => {
        if (value) return "Active";
        return "Inactive";
      },
    },
  },
];

export const ApplicationColumns = [
  {
    name: "id",
    label: "id",
    options: {
      filter: true,
      display: false,
    },
  },
  {
    name: "brand",
    label: "Brand",
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value, tableMeta) => {
        if (!value) return "N/A";
        return (
          <Typography
            onClick={() => {
              if (!value) return;

              window.location.href = "/device/brand/" + value.id;
            }}
            sx={{
              "&:hover": {
                cursor: "pointer",
                textDecoration: "underline",
              },
            }}
          >
            {value.description}
          </Typography>
        );
      },
    },
  },
  {
    name: "lns",
    label: "LNS",
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value, tableMeta) => {
        if (!value) return "N/A";
        return (
          <Typography
            onClick={() => {
              if (!value) return;

              window.location.href = "/lns/" + value.id;
            }}
            sx={{
              "&:hover": {
                cursor: "pointer",
                textDecoration: "underline",
              },
            }}
          >
            {value.name}
          </Typography>
        );
      },
    },
  },
  {
    name: "eu868_profile_id",
    label: "EU868 Profile ID",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "us915_profile_id",
    label: "US915 Profile ID",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "au915_profile_id",
    label: "AU915 Profile ID",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "application_id",
    label: "Application ID",
    options: {
      filter: true,
      sort: true,
    },
  },

  {
    name: "time_updated",
    label: "Date Updated",
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value) => {
        if (!value) return "N/A";
        return formatDate(value);
      },
    },
  },
  {
    name: "active",
    label: "Status",
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value) => {
        if (value) return "Active";
        return "Inactive";
      },
    },
  },
];

export const deviceColumns = [
  {
    name: "id",
    label: "id",
    options: {
      filter: true,
      display: false,
    },
  },
  {
    name: "dev_eui",
    label: "Dev EUI",
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value, tableMeta) => {
        return (
          <Typography
            sx={{
              "&:hover": {
                cursor: "pointer",
                textDecoration: "underline",
              },
            }}
            onClick={() => {
              window.location.href = "/device/" + tableMeta.rowData[0];
            }}
          >
            {value}
          </Typography>
        );
      },
    },
  },
  {
    name: "name",
    label: "Name",
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value, tableMeta) => {
        return (
          <Typography
            onClick={() => {
              if (!value) return;
              window.location.href = "/device/" + tableMeta.rowData[0];
            }}
            sx={{
              "&:hover": {
                cursor: "pointer",
                textDecoration: "underline",
              },
            }}
          >
            {value}
          </Typography>
        );
      },
    },
  },
  {
    name: "device_brand",
    label: "Brand",
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value) => {
        return (
          <Typography
            onClick={() => {
              if (!value) return;
              window.location.href = "/device/brand/" + value.id;
            }}
            sx={{
              "&:hover": {
                cursor: "pointer",
                textDecoration: "underline",
              },
            }}
          >
            {value && value.name ? value.description : "N/A"}
          </Typography>
        );
      },
    },
  },
  {
    name: "time_created",
    label: "Date Added",
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value) => {
        if (!value) return "N/A";
        return formatDate(value);
      },
    },
  },
  {
    name: "time_updated",
    label: "Last Seen",
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value) => {
        if (!value) return "N/A";
        return formatDate(value);
      },
    },
  },
];

let userColumns = [
  {
    name: "id",
    label: "id",
    options: {
      filter: true,
      display: false,
    },
  },

  {
    name: "email",
    label: "Email",
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value, tableMeta) => {
        return (
          <Typography
            sx={{
              "&:hover": {
                cursor: "pointer",
                textDecoration: "underline",
              },
            }}
            onClick={() => {
              window.location.href = "/user/" + tableMeta.rowData[0];
            }}
          >
            {value}
          </Typography>
        );
      },
    },
  },
  {
    name: "time_created",
    label: "Created",
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value) => {
        if (!value) return "N/A";
        return formatDate(value);
      },
    },
  },
];

if (CONFIG.billingEnabled === true) {
  //append billing columns to userColumns

  userColumns.push({
    name: "stripe_customer_id",
    label: "Billing Enabled",
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value) => {
        //if stripe_customer_id is null, return false
        if (!value) return "No";
        return "Yes";
      },
    },
  });

  //append device limit to userColumns
  userColumns.push({
    name: "device_limit",
    label: "Device Limit",
    options: {
      filter: true,
      sort: true,
    },
  });
}

export { userColumns };
