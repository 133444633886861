import React from "react";
import { Button, Tooltip } from "@mui/material";
import { LibraryAdd } from "@mui/icons-material";
import DeviceAddDialog from "../Dialogs/deviceAdd";

export default function AddDeviceButton() {
  const [addDeviceDialogOpen, setAddDeviceDialogOpen] = React.useState(false);

  const handleAddDeviceDialogOpen = () => {
    setAddDeviceDialogOpen(true);
  };

  const handleAddDeviceDialogClose = () => {
    setAddDeviceDialogOpen(false);
  };

  return (
    <>
      <Tooltip title="Add Device">
        <Button variant="outlined" onClick={handleAddDeviceDialogOpen}>
          <LibraryAdd />
        </Button>
      </Tooltip>
      <DeviceAddDialog
        open={addDeviceDialogOpen}
        handleClose={handleAddDeviceDialogClose}
      />
    </>
  );
}
