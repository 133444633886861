import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { postData } from "../../api/Post";
import updateSnackbarMessage from "../../functions/updateSnackbarMessage";

export default function UserAddDialog(props) {
  const { open, handleClose } = props;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const generatePassword = () => {
    //generate a random secure password
    const length = 16;
    const charset =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+~`|}{[]:;?><,./-=";
    let retVal = "";
    for (let i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }

    setPassword(retVal);
  };

  const addUser = () => {
    //check email is not blank and password is not blank
    if (!email) {
      updateSnackbarMessage("Email cannot be blank");
      return;
    }

    if (!password) {
      updateSnackbarMessage("Password cannot be blank");
      return;
    }

    const data = {
      email: email,
      password: password,
    };

    postData("user", data).then((data) => {
      if (!data) {
        updateSnackbarMessage("User add error");
      }

      if (data && data.status && data.status === 200) {
        updateSnackbarMessage("User added");
        handleClose();
      } else if (data && data.status && data.status === 409) {
        updateSnackbarMessage("User already exists");
      } else {
        updateSnackbarMessage("User add error");
      }
    });
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Add User</DialogTitle>
      <DialogContent>
        <FormControl fullWidth>
          <TextField
            id="email"
            label="Email"
            variant="outlined"
            margin="normal"
            value={email}
            required
            fullWidth
            autoFocus
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </FormControl>
        <FormControl fullWidth>
          <TextField
            id="password"
            label="Password"
            variant="outlined"
            margin="normal"
            value={password}
            required
            fullWidth
            autoFocus
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
        </FormControl>
        <Button variant="outlined" onClick={() => generatePassword()}>
          Generate Password
        </Button>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="info" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="outlined" onClick={addUser}>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}
