import React from "react";
import MUIDataTable from "mui-datatables";

export default function DataTableBase(props) {
  const { columns, data, title, orderby } = props;

  return (
    <MUIDataTable
      title={title}
      data={data}
      columns={columns}
      options={{
        selectableRows: "none",
        responsive: "vertical",
        download: true,
        print: false,
        viewColumns: true,
        filter: true,
        search: true,
        sortOrder: {
          name: orderby ? orderby : "time_created",
          direction: "desc",
        },
      }}
    />
  );
}
