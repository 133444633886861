import { Delete } from "@mui/icons-material";
import { Button, Tooltip } from "@mui/material";
import React, { useState } from "react";
import OrganisationDeleteDialog from "../Dialogs/organisationDelete";
import { useNavigate } from "react-router-dom";

export default function DeleteOrganisationButton(props) {
  const navigate = useNavigate();
  const [deleteOrganisationDialogOpen, setDeleteOrganisationDialogOpen] =
    useState(false);

  const handleDeleteOrganisationDialogOpen = () => {
    setDeleteOrganisationDialogOpen(true);
  };

  const handleDeleteOrganisationDialogClose = () => {
    setDeleteOrganisationDialogOpen(false);
    //redirect to organisations
    navigate("/organisations");
  };

  return (
    <>
      <Tooltip title="Delete Organisation">
        <Button
          variant="outlined"
          color="error"
          onClick={handleDeleteOrganisationDialogOpen}
        >
          <Delete />
        </Button>
      </Tooltip>
      <OrganisationDeleteDialog
        open={deleteOrganisationDialogOpen}
        handleClose={handleDeleteOrganisationDialogClose}
        organisation_id={props.organisation_id}
      />
    </>
  );
}
