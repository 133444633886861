import mapboxgl from "mapbox-gl";
import { formatDate } from "./formatDate";
import createGeoJSONCircleFeature from "./createGeoJSONCircleFeature";

//add markers for history
export default async function addHistoryMarkers(history, mapRef) {
  if (!mapRef) return;

  //get features from history.data after json parsing
  let features = [];

  history.forEach((item, index) => {
    let data = item.data;
    //json parse data
    if (typeof data === "string") {
      data = JSON.parse(data);
    }

    //return coordinates
    features.push(
      createGeoJSONCircleFeature(
        [data.longitude, data.latitude],
        30,
        64,
        data.geocode,
        item.time_created,
        index
      )
    );
  });

  //create a geojson object from the features array
  features = {
    type: "FeatureCollection",
    features: features,
  };

  // if features.features.length === 1. empty the array
  if (features.features.length === 1) {
    features.features = [];
  }

  if (!mapRef.getSource("device-history-markers")) {
    //add a source for device history markers
    mapRef.addSource("device-history-markers", {
      type: "geojson",
      data: features,
    });

    //add a layer for device history markers to use standard marker pin
    mapRef.addLayer({
      id: "device-history-markers-layer",
      type: "fill",
      source: "device-history-markers",
      paint: {
        "fill-color": "#4ecca3",
        "fill-opacity": 0.5,
        "fill-outline-color": "#004d40",
        //hide overflows
        "fill-antialias": false,
      },
      //hide overflows
      layout: {
        visibility: "visible",
      },

      filter: ["==", "$type", "Polygon"],
    });

    //when a device history marker is clicked, fly to that location and show a popup
    mapRef.on("click", "device-history-markers-layer", function (e) {
      mapRef.flyTo({
        center: [
          e.features[0].properties.longitude,
          e.features[0].properties.latitude,
        ],
        maxDuration: 2000,
        essential: true,
        animated: true,
      });

      new mapboxgl.Popup()
        .setLngLat([
          e.features[0].properties.longitude,
          e.features[0].properties.latitude,
        ])
        .setHTML(
          "<h3>" +
            formatDate(e.features[0].properties.time) +
            "</h3><p><strong>Location:</strong><br /> " +
            e.features[0].properties.title +
            "</p>"
        )
        .addTo(mapRef);
    });
  }
}
