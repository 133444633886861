import fetchData from "../api/Fetch";

const fetchDeviceHistory = async (deviceID) => {
  //unix datetime for the last 24 hours
  const start_date = Math.floor(Date.now() / 1000) - 86400;
  const end_date = Math.floor(Date.now() / 1000);

  let response = await fetchData(
    "frame/" + deviceID + "/" + start_date + "/" + end_date
  );

  //sort history by time_created ordest to newest
  let dataSorted = response.sort((a, b) => {
    return new Date(a.time_created) - new Date(b.time_created);
  });

  return dataSorted;
};

export default fetchDeviceHistory;
