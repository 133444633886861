import React, { useEffect, useState } from "react";
import { Autocomplete, Dialog, DialogContent, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export default function SearchDialog(props) {
  const { onClose, open } = props;

  const users = useSelector((state) => state.users);
  const organisations = useSelector((state) => state.organisations);
  const devices = useSelector((state) => state.devices);

  const [data, setData] = useState([]);

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      //combine all data into one array
      let combinedData = [];

      if (users) {
        combinedData = combinedData.concat(users);
      }

      if (organisations) {
        combinedData = combinedData.concat(organisations);
      }

      if (devices) {
        combinedData = combinedData.concat(devices);
      }

      setData(combinedData);
    }
  }, [users, organisations, devices]);

  const navigate = useNavigate();

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogContent>
        <Autocomplete
          id="search"
          options={data ? data : []}
          getOptionLabel={(option) =>
            option.name ? option.name : option.email ? option.email : option.id
          }
          sx={{ width: 300 }}
          renderInput={(params) => <TextField {...params} label="Search" />}
          onChange={(event, value) => {
            //check if value is name, email or id
            if (value.name) {
              //check if device or organisation by checking if the value has claimed field
              if (value.claimed) {
                //redirect to device/id
                navigate(`/device/${value.id}`);
              } else {
                //redirect to organisation/id
                navigate(`/organisation/${value.id}`);
              }
            } else if (value.email) {
              //redirect to user/id
              navigate(`/user/${value.id}`);
            } else if (value.id) {
              //redirect to organisation/id
              navigate(`/organisation/${value.id}`);
            } else {
              console.log("error");
            }
            onClose();
          }}
        />
      </DialogContent>
    </Dialog>
  );
}
