import React from "react";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";

import { Business, Home, Person, Router } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { Tooltip } from "@mui/material";

function SidebarLayout() {
  const navigate = useNavigate();

  let theme = useTheme();

  return (
    <Sidebar
      defaultCollapsed={localStorage.getItem("sidebarCollapsed") === "true"}
      className="app"
      backgroundColor={
        theme.palette.mode === "dark" ? "#191B1F" : theme.palette.primary.main
      }
      color={theme.palette.mode === "dark" ? "#8ba1b7" : "#fff"}
    >
      <Menu
        menuItemStyles={{
          button: ({ level, active, disabled }) => {
            if (level === 0) {
              return {
                color: disabled
                  ? "#eee"
                  : theme.palette.mode === "dark"
                  ? "#8ba1b7"
                  : "#fff",
                backgroundColor: active ? "#fff" : undefined,
                "&:hover": {
                  backgroundColor:
                    theme.palette.mode === "dark" ? "#8ba1b7" : "#fff",
                  color:
                    theme.palette.mode === "dark"
                      ? "#fff"
                      : theme.palette.primary.main,
                },
              };
            }
          },
        }}
      >
        <Tooltip title="Home" placement="right">
          <MenuItem onClick={() => navigate("/")} icon={<Home />}>
            Home
          </MenuItem>
        </Tooltip>
        <Tooltip title="Users" placement="right">
          <MenuItem onClick={() => navigate("/user")} icon={<Person />}>
            Users
          </MenuItem>
        </Tooltip>
        <Tooltip title="Devices" placement="right">
          <MenuItem onClick={() => navigate("/device")} icon={<Router />}>
            Devices
          </MenuItem>
        </Tooltip>
        <Tooltip title="Device Brands" placement="right">
          <MenuItem onClick={() => navigate("/device/brand")} icon={<Router />}>
            Device Brands
          </MenuItem>
        </Tooltip>
        <Tooltip title="Organisations" placement="right">
          <MenuItem
            onClick={() => navigate("/organisation")}
            icon={<Business />}
          >
            Organisations
          </MenuItem>
        </Tooltip>
        <Tooltip title="LNS Management" placement="right">
          <MenuItem onClick={() => navigate("/lns")} icon={<Router />}>
            LNS Management
          </MenuItem>
        </Tooltip>
      </Menu>
    </Sidebar>
  );
}

export default SidebarLayout;
