import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import { CONFIG } from "../config";

//mapbox access token
mapboxgl.accessToken = CONFIG.mapboxAccessToken;

// create map function
export default async function createTrackersMap(mapRef, devices) {
  try {
    if (!mapRef.current === null) return;

    let initialLongLat = [0, 0];

    mapRef.current = new mapboxgl.Map({
      container: "map",
      style: CONFIG.mapStyle,
      center: initialLongLat,
      zoom: 16,
    });

    let features = devices.map((device) => {
      let data = JSON.parse(device.last_data);

      if (!data) return null;

      if (!data.latitude || !data.longitude) return null;

      return {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [data.longitude, data.latitude],
        },
        properties: {
          id: device.id,
          name: device.name,
          last_data: device.last_data,
        },
      };
    });

    mapRef.current.on("load", async function () {
      if (devices && devices.length > 0) {
        //add image for location history marker
        mapRef.current.loadImage(
          CONFIG.websiteURL + "/device-icon.png",
          function (error, image) {
            if (error) {
              console.log(error);
              return;
            }
            mapRef.current.addImage("marker-15", image);
          }
        );

        //check if device-marker source exists, if not add it
        if (!mapRef.current.getSource("device-markers")) {
          mapRef.current.addSource("device-markers", {
            type: "geojson",
            data: {
              type: "FeatureCollection",
              features: features,
            },
          });
        } else {
          mapRef.current.getSource("device-markers").setData({
            type: "FeatureCollection",
            features: features,
          });
        }

        //check if device-marker layer exists, if not add it
        if (!mapRef.current.getLayer("device-markers-layer")) {
          mapRef.current.addLayer({
            id: "device-markers-layer",
            type: "symbol",
            source: "device-markers",
            layout: {
              "icon-image": "marker-15",
              "icon-size": 1,
              "icon-allow-overlap": true,
            },
          });
        }

        //add popup on click

        mapRef.current.on("click", "device-markers-layer", function (e) {
          let coordinates = e.features[0].geometry.coordinates.slice();
          let device = e.features[0].properties;

          //json decode last_data and format it key: value, removing dont_log_frame
          let last_data = JSON.parse(device.last_data);

          let last_data_formatted = "";

          for (let key in last_data) {
            if (key === "dont_log_frame") continue;
            last_data_formatted += key + ": " + last_data[key] + "<br>";
          }

          new mapboxgl.Popup()
            .setLngLat(coordinates)
            //black text
            .setHTML(
              "<div style='color: black;'><h3>" +
                device.name +
                "</h3><p>" +
                last_data_formatted +
                "</p>" +
                "<a href='/device/" +
                device.id +
                "'>View Device</a>" +
                "</div>"
            )
            .addTo(mapRef.current);
        });
      }
    });
  } catch (error) {
    console.log(error);
  }
}
