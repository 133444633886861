const initialState = [];

function deviceBrandsReducer(state = initialState, action) {
  switch (action.type) {
    case "deviceBrands/init":
      return action.payload;
    case "deviceBrands/add":
      return [...state.value, action.payload];

    case "deviceBrands/remove":
      return state.value.filter((device) => device.id !== action.payload);
    case "deviceBrands/update":
      return action.payload;

    default:
      return state;
  }
}

export default deviceBrandsReducer;
