import { useTheme } from "@emotion/react";
import { Card, CardHeader, Typography } from "@mui/material";
import React from "react";

export default function CardInfo({
  avatar,
  title,
  value,
  hasEvent = false,
  event,
}) {
  const theme = useTheme();
  return (
    <Card
      sx={{
        backgroundColor: theme.palette.mode === "dark" ? "#191B1F" : "#EFEFEF",
      }}
    >
      <CardHeader
        avatar={avatar}
        title={title}
        titleTypographyProps={{
          variant: "h6",
          color: theme.palette.mode === "dark" ? "#8ba1b7" : "#000",
        }}
        action={
          <Typography
            variant="h6"
            sx={{
              py: 3,
              "&:hover": hasEvent
                ? {
                    color:
                      theme.palette.mode === "dark"
                        ? theme.palette.primary.main
                        : "#000",
                    cursor: "pointer",
                    textDecoration: "underline",
                  }
                : {},
            }}
            {...(hasEvent && { onClick: event })}
          >
            {value}
          </Typography>
        }
      />
    </Card>
  );
}
