import { useTheme } from "@emotion/react";
import { Card, CardHeader, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function CountCard(props) {
  const { title, count, icon } = props;

  let navigate = useNavigate();

  const theme = useTheme();

  return (
    <Card
      onClick={() => {
        let url = `/${title.toLowerCase()}`;
        //drop the s off the end of the title
        url = url.substring(0, url.length - 1);
        navigate(url);
      }}
      sx={{
        "&:hover": {
          cursor: "pointer",
        },
        backgroundColor: theme.palette.mode === "dark" ? "#191B1F" : "#EFEFEF",
      }}
    >
      <CardHeader
        title={title}
        avatar={icon}
        action={
          <Typography sx={{ py: 3 }} variant="h5">
            {count}
          </Typography>
        }
        titleTypographyProps={{
          variant: "h5",
          color: theme.palette.mode === "dark" ? "#8ba1b7" : "#000",
          py: 2,
          pr: 2,
        }}
      />
    </Card>
  );
}
