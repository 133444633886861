import {
  Box,
  Card,
  CardHeader,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DataTableBase from "../components/Tables/dataTableBase";
import { deviceColumns } from "../components/Tables/columnDefs";
import { Person } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { formatDate } from "../functions/formatDate";
import { CONFIG } from "../config";
import PageTitle from "../components/Layout/PageTitle";
import DeleteUserButton from "../components/Buttons/DeleteUser";
import AddOrganisationButton from "../components/Buttons/AddOrganisationButton";

export default function User(props) {
  const [user, setUser] = useState(null);
  const [userDevices, setUserDevices] = useState(null);
  const [userOrganisations, setUserOrganisations] = useState(null);

  const users = useSelector((state) => state.users);
  const organisations = useSelector((state) => state.organisations);
  const devices = useSelector((state) => state.devices);

  const params = useParams();

  const theme = useTheme();

  const navigate = useNavigate();

  const organisationColumns = [
    {
      name: "id",
      label: "id",
      options: {
        display: false,
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <Typography
              sx={{
                "&:hover": {
                  cursor: "pointer",
                  textDecoration: "underline",
                },
              }}
              onClick={() => {
                navigate("/organisation/" + tableMeta.rowData[0]);
              }}
            >
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: "owner",
      label: "Owner",
      options: {
        customBodyRender: (value, tableMeta) => {
          if (!value) return "None";
          return (
            <Typography
              sx={{
                "&:hover": {
                  cursor: "pointer",
                  textDecoration: "underline",
                },
              }}
              onClick={() => {
                window.location.href = "/user/" + tableMeta.rowData[2];
              }}
            >
              {value.email}
            </Typography>
          );
        },
      },
    },
    {
      name: "active",
      label: "Status",
      options: {
        customBodyRender: (value) => {
          if (value) return "Active";
          return "Inactive";
        },
      },
    },
    {
      name: "time_created",
      label: "Date Added",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          if (!value) return "N/A";
          return formatDate(value);
        },
      },
    },
    {
      name: "time_updated",
      label: "Last Updated",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          if (!value) return "N/A";
          return formatDate(value);
        },
      },
    },
  ];

  useEffect(() => {
    if (users) {
      //filter users by id
      let filtered = users.filter((user) => {
        // eslint-disable-next-line
        return user.id == params.id;
      });

      //set user
      if (filtered && filtered.length) {
        setUser(filtered[0]);
      }
    }

    if (organisations.length) {
      //filter organisations by user_id
      let filteredOrganisations = organisations.filter((organisation) => {
        // eslint-disable-next-line
        return organisation.owner_id == params.id;
      });

      //set userOrganisations
      setUserOrganisations(filteredOrganisations);

      if (devices.length) {
        //filter devices by userOrganisations
        let filteredDevices = devices.filter((device) => {
          // eslint-disable-next-line
          //filter where organisation_id is in userOrganisations
          return filteredOrganisations.some(
            (organisation) => organisation.id === device.organisation_id
          );
        });

        //set userDevices
        setUserDevices(filteredDevices);
      }
    }
  }, [users, devices, organisations]);

  return (
    <>
      <Grid container spacing={1}>
        <PageTitle title={user && user.email ? user.email : "N/A"}>
          <DeleteUserButton id={params.id} />
        </PageTitle>
      </Grid>

      {
        // billing info
      }
      {CONFIG.billingEnabled && (
        <Box
          sx={{
            py: 2,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
              <Card
                sx={{
                  backgroundColor:
                    theme.palette.mode === "dark" ? "#191B1F" : "#EFEFEF",
                }}
              >
                <CardHeader
                  avatar={<Person sx={{ fontSize: "2em" }} />}
                  title="Billing Status"
                  titleTypographyProps={{
                    variant: "h6",
                    color: theme.palette.mode === "dark" ? "#8ba1b7" : "#000",
                  }}
                  action={
                    <Typography variant="h6" sx={{ py: 3 }}>
                      {user && user.stripe_billing_status
                        ? //upper case first letter
                          user.stripe_billing_status.charAt(0).toUpperCase() +
                          user.stripe_billing_status.slice(1)
                        : "N/A"}
                    </Typography>
                  }
                />
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
              <Card
                sx={{
                  backgroundColor:
                    theme.palette.mode === "dark" ? "#191B1F" : "#EFEFEF",
                }}
              >
                <CardHeader
                  avatar={<Person sx={{ fontSize: "2em" }} />}
                  title="Device Limit"
                  titleTypographyProps={{
                    variant: "h6",
                    color: theme.palette.mode === "dark" ? "#8ba1b7" : "#000",
                  }}
                  action={
                    <Typography variant="h6" sx={{ py: 3 }}>
                      {user && user.device_limit ? user.device_limit : "N/A"}
                    </Typography>
                  }
                />
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
              <Card
                sx={{
                  backgroundColor:
                    theme.palette.mode === "dark" ? "#191B1F" : "#EFEFEF",
                }}
              >
                <CardHeader
                  avatar={<Person sx={{ fontSize: "2em" }} />}
                  title="Customer"
                  titleTypographyProps={{
                    variant: "h6",
                    color: theme.palette.mode === "dark" ? "#8ba1b7" : "#000",
                  }}
                  action={
                    <Typography
                      variant="h6"
                      //overflows to new line
                      overflow={"hidden"}
                      sx={{
                        py: 3,
                        cursor: "pointer",
                        "&:hover": {
                          color: theme.palette.primary.main,
                          textDecoration: "underline",
                        },
                      }}
                      onClick={() => {
                        window.open(
                          "https://dashboard.stripe.com/customers/" +
                            user.stripe_customer_id,
                          "_blank"
                        );
                      }}
                    >
                      {user && user.stripe_customer_id
                        ? user.stripe_customer_id
                        : "N/A"}
                    </Typography>
                  }
                />
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
              <Card
                sx={{
                  backgroundColor:
                    theme.palette.mode === "dark" ? "#191B1F" : "#EFEFEF",
                }}
              >
                <CardHeader
                  avatar={<Person sx={{ fontSize: "2em" }} />}
                  title="Subscription"
                  titleTypographyProps={{
                    variant: "h6",
                    color: theme.palette.mode === "dark" ? "#8ba1b7" : "#000",
                  }}
                  action={
                    <Typography
                      variant="h6"
                      //overflows to new line

                      sx={{
                        py: 3,
                        cursor: "pointer",
                        "&:hover": {
                          color: theme.palette.primary.main,
                          textDecoration: "underline",
                        },
                        overflow: "hidden",
                      }}
                      onClick={() => {
                        window.open(
                          "https://dashboard.stripe.com/subscriptions/" +
                            user.stripe_subscription_id,
                          "_blank"
                        );
                      }}
                    >
                      {user && user.stripe_subscription_id
                        ? user.stripe_subscription_id
                        : "N/A"}
                    </Typography>
                  }
                />
              </Card>
            </Grid>
          </Grid>
        </Box>
      )}
      <Grid container spacing={1}>
        <PageTitle title="Organisations">
          <AddOrganisationButton user_id={params.id} />
        </PageTitle>
        <Grid item xs={12}>
          <DataTableBase
            columns={organisationColumns}
            data={
              userOrganisations && userOrganisations.length
                ? userOrganisations
                : []
            }
          />
        </Grid>

        <PageTitle title="Devices"></PageTitle>
        <Grid item xs={12}>
          <DataTableBase
            columns={deviceColumns}
            data={userDevices && userDevices.length ? userDevices : []}
          />
        </Grid>
      </Grid>
    </>
  );
}
