import { withAuthenticationRequired } from "@auth0/auth0-react";
import React from "react";
import { Grid } from "@mui/material";
import OrganisationsTable from "../components/Tables/OrganisationsTable";
import PageTitle from "../components/Layout/PageTitle";
import AddOrganisationButton from "../components/Buttons/AddOrganisationButton";

function Organisations() {
  return (
    <Grid container spacing={1}>
      <PageTitle title="Organisations">
        <AddOrganisationButton />
      </PageTitle>
      <Grid item xs={12}>
        <OrganisationsTable />
      </Grid>
    </Grid>
  );
}

export default withAuthenticationRequired(Organisations);
