import jsPDF from "jspdf";
import fetchData from "../api/Fetch";
import { CONFIG } from "../config";

export default async function downloadPDF(deviceID, brandName) {
  //take the device total, claimed, unclaimed, active in the last 30 days and inactive in the last 30 days
  //and put them in a pdf

  //get the data
  let response = await fetchData("device/brand/" + deviceID);
  let devices = response;

  //format the data

  //get the total number of devices
  let totalDevices = devices.length;
  let claimedDevices = 0;
  let unclaimedDevices = 0;
  let activeDevices = 0;
  let inactiveDevices = 0;

  //get the number of claimed devices
  devices.forEach((device) => {
    if (device.claimed) {
      claimedDevices++;
    } else {
      unclaimedDevices++;
    }
  });

  //get the number of active devices in the last 30 days
  devices.forEach((device) => {
    let time_updated = new Date(device.time_updated);
    let now = new Date();
    if (now.getTime() - time_updated.getTime() < 30 * 24 * 60 * 60 * 1000) {
      //check if last_data is not null
      if (device.last_data !== null) {
        activeDevices++;
      } else {
        inactiveDevices++;
      }
    } else {
      inactiveDevices++;
    }
  });

  //create the pdf
  const doc = new jsPDF();

  //add date
  doc.setFontSize(12);
  let date = new Date();
  //format date dd-mm-yyyy
  let datestring =
    date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();

  //add logo to top middle of page
  doc.addImage("/logo.png", "PNG", 40, 10, 120, 45);

  //add title
  doc.setFontSize(30);
  doc.text(CONFIG.site_title + " Device Report - " + datestring, 10, 70);

  //add subtitle
  doc.setFontSize(16);
  doc.text("Brand Name: " + brandName, 10, 90);

  //add data
  doc.setFontSize(12);
  doc.text("Total Devices: " + totalDevices, 10, 100);
  doc.text("Claimed Devices: " + claimedDevices, 10, 110);
  doc.text("Unclaimed Devices: " + unclaimedDevices, 10, 120);
  doc.text("Active Devices: " + activeDevices, 10, 130);
  doc.text("Inactive Devices: " + inactiveDevices, 10, 140);

  let docName = brandName + "-report" + datestring + ".pdf";

  doc.save(docName);
}
