import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Stack,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { postData } from "../../api/Post";
import updateSnackbarMessage from "../../functions/updateSnackbarMessage";
import { useSelector } from "react-redux";

export default function OrganisationAddDialog(props) {
  const { open, handleClose, user_id } = props;

  const [organisation, setOrganisation] = useState({
    name: "",
    description: "",
    owner_id: user_id,
  });

  const users = useSelector((state) => state.users);

  const addOrganisation = () => {
    //check for name, description and owner_id
    if (!organisation.name) {
      updateSnackbarMessage("Organisation name cannot be blank");
      return;
    }

    if (!organisation.description) {
      updateSnackbarMessage("Organisation description cannot be blank");

      return;
    }

    if (!organisation.owner_id) {
      updateSnackbarMessage("Organisation owner cannot be blank");
      return;
    }

    //post data
    postData("organisation", organisation).then((data) => {
      if (!data) {
        updateSnackbarMessage("Organisation add error");
      }

      if (data && data.status && data.status === 200) {
        updateSnackbarMessage("Organisation added");
        handleClose();
      } else if (data && data.status && data.status === 409) {
        updateSnackbarMessage("Organisation already exists");
      } else {
        updateSnackbarMessage("Organisation add error");
      }
    });
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxSize="sm">
      <DialogTitle>Add Organisation</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <FormControl fullWidth>
            <TextField
              id="name"
              label="Organisation Name"
              variant="outlined"
              required
              fullWidth
              autoFocus
              onChange={(e) => {
                if (!e.target.value) return;
                setOrganisation({ ...organisation, name: e.target.value });
              }}
            />
          </FormControl>
          <FormControl fullWidth>
            <TextField
              id="description"
              label="Description"
              variant="outlined"
              required
              fullWidth
              onChange={(e) => {
                if (!e.target.value) return;
                setOrganisation({
                  ...organisation,
                  description: e.target.value,
                });
              }}
            />
          </FormControl>
          <FormControl fullWidth>
            <Autocomplete
              id="owner"
              options={users}
              value={
                users.find(
                  (user) => user.id === parseInt(organisation.owner_id)
                ) || {}
              }
              getOptionLabel={(option) => option.email}
              onChange={(e, value) => {
                if (!e.target.value) return;
                setOrganisation({ ...organisation, owner_id: value.id });
              }}
              renderInput={(params) => (
                <TextField {...params} label="Owner" variant="outlined" />
              )}
            />
          </FormControl>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={addOrganisation}>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}
