import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import fetchData from "../../api/Fetch";
import { postData } from "../../api/Post";
import downloadQRCode from "../../functions/downQRCode";
import updateSnackbarMessage from "../../functions/updateSnackbarMessage";
import { useSelector } from "react-redux";

export default function DeviceAddDialog(props) {
  const { open, handleClose } = props;
  const [device_brands, setDeviceBrands] = useState([]);
  const [device_name, setDeviceName] = useState("");
  const [dev_eui, setDevEUI] = useState("");
  const [app_eui, setAppEUI] = useState("");
  const [app_key, setAppKey] = useState("");
  const [lns_id, setLNS] = useState();
  const [device_brand, setDeviceBrand] = useState("");
  const [region, setRegion] = useState("EU868");

  const lns = useSelector((state) => state.lns);

  useEffect(() => {
    fetchData("device/brands").then((data) => {
      setDeviceBrands(data);
    });
  }, []);

  const addDevice = () => {
    //check name is not blank, dev_eui is 16 chars, app_eui is 16 chars, app_key is 32 chars and device_brand is not blank
    if (!device_name) {
      updateSnackbarMessage("Device name cannot be blank");
      return;
    }

    //check device brand is not non_lora = 1
    //get device brand
    let brand = device_brands.find((brand) => brand.id === device_brand);
    if (brand.non_lora === 0) {
      if (dev_eui.length !== 16) {
        updateSnackbarMessage("Device EUI must be 16 characters");
        return;
      }

      if (app_eui.length !== 16) {
        updateSnackbarMessage("App EUI must be 16 characters");
        return;
      }

      if (app_key.length !== 32) {
        updateSnackbarMessage("App Key must be 32 characters");
        return;
      }

      if (!lns_id) {
        updateSnackbarMessage("LNS cannot be blank");
        return;
      }
    }

    if (!device_brand) {
      updateSnackbarMessage("Device brand cannot be blank");
      return;
    }

    const data = {
      name: device_name,
      dev_eui: dev_eui,
      app_eui: app_eui,
      app_key: app_key,
      lns_id: lns_id,
      device_brand_id: device_brand,
      region: region,
    };
    postData("device", data).then((data) => {
      if (!data) {
        updateSnackbarMessage("Device add error");
      }

      if (data && data.status && data.status === 200) {
        updateSnackbarMessage("Device added");
        downloadQRCode(data.data.claim_code, device_name);
        handleClose();
      } else if (data && data.status && data.status === 409) {
        updateSnackbarMessage("Device already exists");
      } else {
        updateSnackbarMessage("Device add error");
      }
    });
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Add Device</DialogTitle>
      <DialogContent>
        <FormControl fullWidth>
          <TextField
            id="deviceName"
            label="Device Name"
            variant="outlined"
            margin="normal"
            required
            fullWidth
            autoFocus
            onChange={(e) => {
              setDeviceName(e.target.value);
            }}
          />
        </FormControl>
        <FormControl fullWidth>
          <TextField
            id="device_brand"
            label="Device Brand"
            variant="outlined"
            margin="normal"
            required
            fullWidth
            select
            onChange={(e) => {
              setDeviceBrand(e.target.value);
            }}
          >
            {device_brands &&
              device_brands.map((brand) => {
                return (
                  <MenuItem key={brand.id} value={brand.id}>
                    {brand.description}
                  </MenuItem>
                );
              })}
          </TextField>
        </FormControl>

        <FormControl fullWidth>
          <TextField
            id="dev_eui"
            label={
              device_brand &&
              device_brands.find((brand) => brand.id === device_brand).non_lora
                ? "Device ID"
                : "Dev EUI"
            }
            variant="outlined"
            margin="normal"
            required
            fullWidth
            onChange={(e) => {
              setDevEUI(e.target.value);
            }}
          />
        </FormControl>

        {
          //if device brand non_lora=0 then show these fields
          device_brand &&
            !device_brands.find((brand) => brand.id === parseInt(device_brand))
              .non_lora && (
              <>
                <FormControl fullWidth>
                  <TextField
                    id="app_eui"
                    label="App EUI"
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    onChange={(e) => {
                      setAppEUI(e.target.value);
                    }}
                  />
                </FormControl>

                <FormControl fullWidth>
                  <TextField
                    id="app_key"
                    label="App Key"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    onChange={(e) => {
                      setAppKey(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="lns"
                    label="LNS"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    select
                    onChange={(e) => {
                      setLNS(e.target.value);
                    }}
                  >
                    {lns &&
                      lns.map((lns) => {
                        return (
                          <MenuItem key={lns.id} value={lns.id}>
                            {lns.name}
                          </MenuItem>
                        );
                      })}
                  </TextField>
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    id="Region"
                    label="Region"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    select
                    onChange={(e) => {
                      setRegion(e.target.value);
                    }}
                  >
                    <MenuItem key="EU868" value="EU868">
                      EU868
                    </MenuItem>
                    <MenuItem key="US915" value="US915">
                      US915
                    </MenuItem>
                    <MenuItem key="AU915" value="AU915">
                      AU915
                    </MenuItem>
                  </TextField>
                </FormControl>
              </>
            )
        }
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={addDevice}>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}
