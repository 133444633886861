import React, { useEffect } from "react";
import DataTableBase from "./dataTableBase";
import { fetchOrganisationActions } from "../../functions/fetchOrganisationActions";

export default function OrganisationActions(props) {
  const { organisation_id } = props;

  const [actions, setActions] = React.useState([]);

  /*
  above_below
: 
"below"
action_type
: 
"alert"
active
: 
1
alert_type
: 
"battery"
contacts
: 
null
devices
: 
null
end_time
: 
null
forwarder_headers
: 
""
forwarder_url
: 
""
id
: 
168
message
: 
"1"
name
: 
"My Action"
organisation_id
: 
2
out_of_hours
: 
0
recipient
: 
""
recipient_type
: 
""
start_time
: 
null
temp_unit
: 
"C"
threshold
: 
0
time_created
: 
"2023-12-30T11:33:14"
time_updated
: 
"2023-12-30T11:33:14"
*/

  const actionColumns = [
    {
      name: "id",
      label: "ID",
      options: {
        sort: true,
        display: false,
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        sort: true,
        filter: true,
      },
    },
    {
      name: "action_type",
      label: "Action Type",
      options: {
        sort: true,
        filter: true,
      },
    },
    {
      name: "active",
      label: "Active",
      options: {
        sort: true,
        filter: true,
      },
    },
    {
      name: "alert_type",
      label: "Alert Type",
      options: {
        sort: true,
        filter: true,
      },
    },
    {
      name: "message",
      label: "Message",
      options: {
        sort: true,
        filter: true,
      },
    },
    {
      name: "out_of_hours",
      label: "Out of Hours",
      options: {
        sort: true,
        filter: true,
      },
    },
    {
      name: "threshold",
      label: "Threshold",
      options: {
        sort: true,
        filter: true,
      },
    },
  ];

  useEffect(() => {
    fetchOrganisationActions(organisation_id).then((actions) => {
      setActions(actions);
    });
  }, [organisation_id]);

  return (
    <DataTableBase
      title={"Organisation Actions"}
      data={actions}
      columns={actionColumns}
      orderby="time_created"
      orderdirection="desc"
    />
  );
}
