import { useTheme } from "@emotion/react";
import { Card, CardHeader } from "@mui/material";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { formatDateDateOnly } from "../../functions/formatDate";

export default function TotalUsersThisYear() {
  const users = useSelector((state) => state.users);

  const [totalUsers, setTotalUsers] = React.useState(0);

  const theme = useTheme();

  useEffect(() => {
    //per month of the current year, show a running total of user totals, e.g. Jan: 10, Feb: 20, Mar: 30
    let totalUsers = [];
    let runningTotal = 0;
    let currentMonth = new Date().getMonth();
    let currentYear = new Date().getFullYear();

    for (let i = 0; i <= currentMonth; i++) {
      let monthTotal = users.filter((user) => {
        let time_created = new Date(user.time_created);
        return (
          time_created.getMonth() === i &&
          time_created.getFullYear() === currentYear
        );
      }).length;
      runningTotal += monthTotal;
      totalUsers.push({
        time_created: new Date(currentYear, i).getTime(),
        count: runningTotal,
      });
    }

    setTotalUsers(totalUsers);
  }, [users]);

  return (
    <Card
      sx={{
        backgroundColor: theme.palette.mode === "dark" ? "#191B1F" : "#EFEFEF",
      }}
    >
      <CardHeader
        title="Total Users added this year"
        titleTypographyProps={{
          variant: "h5",
          color: theme.palette.mode === "dark" ? "#8ba1b7" : "#000",
        }}
      />
      <ResponsiveContainer width="100%" height={300}>
        <AreaChart
          data={totalUsers}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="time_created"
            tickFormatter={(time_created) => {
              return formatDateDateOnly(time_created);
            }}
          />
          <YAxis dataKey="count" />
          <Tooltip
            //check thememode and change background color accordingly
            contentStyle={{
              backgroundColor:
                theme.palette.mode === "dark" ? "#191B1F" : "#EFEFEF",
            }}
            labelStyle={{
              color: theme.palette.mode === "dark" ? "#8ba1b7" : "#000",
            }}
            //format date of tooltip
            labelFormatter={(time_created) => {
              return formatDateDateOnly(time_created);
            }}
          />
          <Area
            type="monotone"
            dataKey="count"
            stroke={theme.palette.primary.main}
            fill={theme.palette.primary.main}
          />
        </AreaChart>
      </ResponsiveContainer>
    </Card>
  );
}
