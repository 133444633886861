import React from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { Grid } from "@mui/material";
import { Router } from "@mui/icons-material";
import DevicesTable from "../components/Tables/DevicesTable";
import { useSelector } from "react-redux";
import DevicesGraph from "../components/Graphs/DevicesGraph";
import PageTitle from "../components/Layout/PageTitle";
import AddDeviceButton from "../components/Buttons/AddDeviceButton";
import CardInfo from "../components/Cards/CardInfo";
import TotalDevicesThisYear from "../components/Graphs/TotalDevicesThisYear";

function Devices() {
  const devices = useSelector((state) => state.devices);

  return (
    <Grid container spacing={1}>
      <PageTitle title="Devices">
        <AddDeviceButton />
      </PageTitle>
      <Grid item xs={12} sm={6}>
        <DevicesGraph dateLength={30} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TotalDevicesThisYear />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
        <CardInfo
          avatar={<Router sx={{ fontSize: "4em" }} />}
          title="Total Devices"
          value={devices ? devices.length : "N/A"}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
        <CardInfo
          avatar={<Router sx={{ fontSize: "4em" }} />}
          title="Devices Added Today"
          value={
            devices && devices.length
              ? devices.filter((device) => {
                  let time_created = new Date(device.time_created);
                  let now = new Date();
                  return (
                    now.getTime() - time_created.getTime() < 24 * 60 * 60 * 1000
                  );
                }).length
              : "N/A"
          }
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
        <CardInfo
          avatar={<Router sx={{ fontSize: "4em" }} />}
          title="Devices Added This Week"
          value={
            devices && devices.length
              ? devices.filter((device) => {
                  let time_created = new Date(device.time_created);
                  let now = new Date();

                  //return devices added this week
                  return (
                    now.getTime() - time_created.getTime() <
                    7 * 24 * 60 * 60 * 1000
                  );
                }).length
              : "N/A"
          }
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
        <CardInfo
          avatar={<Router sx={{ fontSize: "4em" }} />}
          title="Devices Added This Month"
          value={
            devices && devices.length
              ? devices.filter((device) => {
                  let time_created = new Date(device.time_created);
                  //filter devices added this month
                  return (
                    time_created.getMonth() === new Date().getMonth() &&
                    time_created.getFullYear() === new Date().getFullYear()
                  );
                }).length
              : "N/A"
          }
        />
      </Grid>
      <Grid item xs={12}>
        <DevicesTable />
      </Grid>
    </Grid>
  );
}

export default withAuthenticationRequired(Devices);
