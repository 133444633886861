import fetchData from "../api/Fetch";
import { store } from "../store";

export default async function fetchDevices() {
  let response = await fetchData("device/brands");

  //dispatch to users/update reducer
  store.dispatch({
    type: "deviceBrands/update",
    payload: response ? response : [],
  });
}
