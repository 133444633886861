import React from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import DeviceBrandsTable from "../components/Tables/DeviceBrandTable";
import { Button, Grid } from "@mui/material";
import PageTitle from "../components/Layout/PageTitle";
import { Addchart } from "@mui/icons-material";

function DeviceBrands() {
  return (
    <>
      <Grid container spacing={1}>
        <PageTitle title="Device Brands">
          <Button variant="outlined" color="primary" disabled>
            <Addchart />
          </Button>
        </PageTitle>
        <Grid item xs={12}>
          <DeviceBrandsTable />
        </Grid>
      </Grid>
    </>
  );
}

export default withAuthenticationRequired(DeviceBrands);
