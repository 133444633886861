import React from "react";
import DataTableBase from "./dataTableBase";
import { deviceColumns } from "./columnDefs";
import { useSelector } from "react-redux";

export default function DevicesTable() {
  const devices = useSelector((state) => state.devices);

  return (
    <DataTableBase
      title={"Devices"}
      data={devices || []}
      columns={deviceColumns}
      orderby="time_created"
      orderdirection="desc"
    />
  );
}
