import { withAuthenticationRequired } from "@auth0/auth0-react";
import { Box, Typography } from "@mui/material";
import React from "react";

function Settings(props) {
  return (
    <Box
      sx={{
        pb: 1,
      }}
    >
      <Typography variant="h6">Settings</Typography>
    </Box>
  );
}

export default withAuthenticationRequired(Settings);
