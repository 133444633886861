import { useTheme } from "@emotion/react";
import { Person } from "@mui/icons-material";
import { Card, CardHeader, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

export default function TotalUsersCard(props) {
  const theme = useTheme();

  const users = useSelector((state) => state.users);

  return (
    <Card
      sx={{
        "&:hover": {
          cursor: "pointer",
        },
        backgroundColor: theme.palette.mode === "dark" ? "#191B1F" : "#EFEFEF",
      }}
    >
      <CardHeader
        avatar={<Person fontSize="large" />}
        title="Total Users"
        titleTypographyProps={{
          variant: "h5",
          color: theme.palette.mode === "dark" ? "#8ba1b7" : "#000",
          py: 2,
          pr: 2,
        }}
        action={
          <Typography variant="h4" sx={{ py: 2 }}>
            {users && users.length ? users.length : "N/A"}
          </Typography>
        }
      />
    </Card>
  );
}
