import { useTheme } from "@emotion/react";
import { PersonAdd } from "@mui/icons-material";
import { Card, CardHeader, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

export default function NewSignupsCard(props) {
  const theme = useTheme();

  const users = useSelector((state) => state.users);
  const length = props.length;

  return (
    <Card
      sx={{
        "&:hover": {
          cursor: "pointer",
        },
        backgroundColor: theme.palette.mode === "dark" ? "#191B1F" : "#EFEFEF",
      }}
    >
      <CardHeader
        avatar={<PersonAdd sx={{ fontSize: "2em" }} />}
        title={
          "Signups " +
          (length === "monthly"
            ? "This Month"
            : length === "weekly"
            ? "This Week"
            : "Today")
        }
        titleTypographyProps={{
          variant: "h5",
          color: theme.palette.mode === "dark" ? "#8ba1b7" : "#000",
          py: 2,
          pr: 1,
        }}
        action={
          <Typography variant="h4" sx={{ py: 2 }}>
            {
              //filter users by date created within this calendar month
              users && users.length
                ? users.filter((user) => {
                    if (length === "monthly") {
                      let date = new Date(user.time_created);
                      let now = new Date();
                      return (
                        date.getMonth() === now.getMonth() &&
                        date.getFullYear() === now.getFullYear()
                      );
                    } else if (length === "weekly") {
                      const today = new Date();
                      const day = today.getDay();
                      const diff = today.getDate() - day + (day === 0 ? -6 : 1); // adjust for Sunday (0) to be counted as the last day of the week

                      const startOfWeek = new Date(today);
                      startOfWeek.setDate(diff);
                      startOfWeek.setHours(0, 0, 0, 0);

                      //add 6 days to startOfWeek
                      const endOfWeek = new Date(startOfWeek);
                      endOfWeek.setDate(startOfWeek.getDate() + 6);

                      let date = new Date(user.time_created);
                      return date >= startOfWeek && date <= endOfWeek;
                    } else if (length === "today") {
                      let date = new Date(user.time_created);
                      let now = new Date();
                      return (
                        date.getMonth() === now.getMonth() &&
                        date.getFullYear() === now.getFullYear() &&
                        date.getDate() === now.getDate()
                      );
                    }

                    return false;
                  }).length
                : "N/A"
            }
          </Typography>
        }
      />
    </Card>
  );
}
