import React, { useState } from "react";
import { Button, Grid } from "@mui/material";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import LNSTable from "../components/Tables/LNSTable";
import LNSAddDialog from "../components/Dialogs/lnsAdd";
import ApplicationsTable from "../components/Tables/ApplicationsTable";
import ApplicationAddDialog from "../components/Dialogs/applicationAdd";
import refreshData from "../functions/refreshData";
import PageTitle from "../components/Layout/PageTitle";

function LNSs() {
  const [lnsAddDialogOpen, setLNSAddDialogOpen] = useState(false);
  const [applicationAddDialogOpen, setApplicationAddDialogOpen] =
    useState(false);

  return (
    <>
      <Grid container spacing={1}>
        <PageTitle title="LNSs">
          <Button
            variant="outlined"
            onClick={() => {
              setLNSAddDialogOpen(true);
            }}
          >
            Add LNS
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              setApplicationAddDialogOpen(true);
            }}
          >
            Add Application
          </Button>
        </PageTitle>
        <Grid item xs={12}>
          <LNSTable />
        </Grid>
        <Grid item xs={12}>
          <ApplicationsTable />
        </Grid>
      </Grid>
      <LNSAddDialog
        open={lnsAddDialogOpen}
        handleClose={() => {
          setLNSAddDialogOpen(false);
          refreshData();
        }}
      />
      <ApplicationAddDialog
        open={applicationAddDialogOpen}
        handleClose={() => {
          setApplicationAddDialogOpen(false);
          refreshData();
        }}
      />
    </>
  );
}

export default withAuthenticationRequired(LNSs);
