import React from "react";
import { Grid } from "@mui/material";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import UsersTable from "../components/Tables/UsersTable";
import NewSignupsCard from "../components/Cards/NewSignups";
import TotalUsersCard from "../components/Cards/TotalUsersCard";
import UsersGraph from "../components/Graphs/UsersGraph";
import PageTitle from "../components/Layout/PageTitle";
import AddUserButton from "../components/Buttons/AddUserButton";
import TotalUsersThisYear from "../components/Graphs/TotalUsersThisYear";

function Users() {
  return (
    <Grid container spacing={1}>
      <PageTitle title="Users">
        <AddUserButton />
      </PageTitle>
      <Grid item xs={12} sm={12} md={6}>
        <UsersGraph dateLength={30} />
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <TotalUsersThisYear />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
        <TotalUsersCard />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
        <NewSignupsCard length="today" />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
        <NewSignupsCard length="weekly" />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
        <NewSignupsCard length="monthly" />
      </Grid>
      <Grid item xs={12}>
        <UsersTable />
      </Grid>
    </Grid>
  );
}

export default withAuthenticationRequired(Users);
