import React, { useEffect, useState } from "react";
import { fetchOrganisationMembers } from "../../functions/fetchOrganisationMembers";
import DataTableBase from "./dataTableBase";
import { useSelector } from "react-redux";
import { Typography } from "@mui/material";
import { Button } from "@mui/material";
import ConfirmDeleteMember from "../Dialogs/ConfirmDeleteMember";

export default function OrganisationMembersTable(props) {
  const [organisationMembers, setOrganisationMembers] = useState([]);

  const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false);
  const [userID, setUserID] = useState(null);

  const [selectedRows, setSelectedRows] = useState([]);

  const users = useSelector((state) => state.users);

  const organisationMembersColumns = [
    {
      name: "id",
      label: "id",
      options: {
        display: false,
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <Typography
              onClick={() => {
                window.location.href = "/user/" + tableMeta.rowData[0];
              }}
              sx={{
                "&:hover": {
                  cursor: "pointer",
                  textDecoration: "underline",
                },
              }}
            >
              {
                //find user by id and return email
              }
              {users && users.length
                ? users.filter((user) => user.id === tableMeta.rowData[0])[0]
                  ? users.filter((user) => user.id === tableMeta.rowData[0])[0]
                      .email
                  : ""
                : ""}
            </Typography>
          );
        },
      },
    },
    {
      name: "options",
      label: "Options",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <Button
              variant="outlined"
              color="error"
              onClick={() => {
                setConfirmDeleteDialogOpen(true);
                setUserID(tableMeta.rowData[0]);
              }}
            >
              Remove
            </Button>
          );
        },
      },
    },
  ];

  useEffect(() => {
    if (!props.organisation_id) return;
    fetchOrganisationMembers(props.organisation_id).then((data) => {
      setOrganisationMembers(data);
    });
  }, [props.organisation_id]);

  return (
    <>
      <DataTableBase
        title={"Organisation Members"}
        data={organisationMembers}
        columns={organisationMembersColumns}
        orderby="time_updated"
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        selectedItems
      />
      <ConfirmDeleteMember
        open={confirmDeleteDialogOpen}
        handleClose={() => {
          setConfirmDeleteDialogOpen(false);
          fetchOrganisationMembers(props.organisation_id).then((data) => {
            setOrganisationMembers(data);
          });
        }}
        user_id={userID}
        organisation_id={props.organisation_id}
      />
    </>
  );
}
