const initialState = [];

function lnsReducer(state = initialState, action) {
  switch (action.type) {
    case "lns/init":
      return action.payload;

    case "lns/add":
      return [...state.value, action.payload];
    case "lns/remove":
      return state.value.filter((device) => device.id !== action.payload);
    case "lns/update":
      return action.payload;

    default:
      return state;
  }
}

export default lnsReducer;
