import React from "react";
import { Grid, Button, Skeleton } from "@mui/material";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { Router } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import downloadPDF from "../functions/downloadPDF";
import { useSelector } from "react-redux";
import DeviceBrandDevicesTable from "../components/Tables/DeviceBrandDevicesTable";
import DeviceBrandGraph from "../components/Graphs/DeviceBrandGraph";
import CardInfo from "../components/Cards/CardInfo";
import PageTitle from "../components/Layout/PageTitle";

function DeviceBrand() {
  const params = useParams();

  const devices = useSelector((state) => state.devices);
  const deviceBrands = useSelector((state) => state.deviceBrands);
  let brandName = "";

  //filter devices by brand
  let devicesFiltered = devices.filter(
    (device) => device.device_brand_id === parseInt(params.id)
  );

  //find device brand using params.id
  let deviceBrand = deviceBrands.find(
    (deviceBrand) => deviceBrand.id === parseInt(params.id)
  );

  console.log(deviceBrand, "deviceBrand");

  if (deviceBrand) {
    brandName = deviceBrand.description;
  }

  return (
    <Grid container spacing={1}>
      <PageTitle
        title={brandName ? brandName : <Skeleton variant="text" width={200} />}
        showBack
      >
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            downloadPDF(params.id, brandName);
          }}
        >
          Download Report
        </Button>
      </PageTitle>
      <Grid item xs={12} md={12}>
        <DeviceBrandGraph dateLength={30} deviceBrand={parseInt(params.id)} />
      </Grid>
      <Grid item xs={12} md={3}>
        <CardInfo
          avatar={<Router fontSize="large" />}
          title="Total Devices"
          value={
            devicesFiltered && devicesFiltered.length
              ? devicesFiltered.length
              : "N/A"
          }
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <CardInfo
          avatar={<Router fontSize="large" />}
          title="Devices Heard Last 24hr"
          value={
            devicesFiltered && devicesFiltered.length
              ? devicesFiltered.filter((device) => {
                  let time_updated = new Date(device.time_updated);
                  let now = new Date();
                  return (
                    now.getTime() - time_updated.getTime() < 24 * 60 * 60 * 1000
                  );
                }).length
              : "N/A"
          }
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <CardInfo
          avatar={<Router fontSize="large" />}
          title="Claimed Devices"
          value={
            devicesFiltered && devicesFiltered.length
              ? devicesFiltered.filter((device) => device.claimed).length
              : "N/A"
          }
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <CardInfo
          avatar={<Router fontSize="large" />}
          title="Unclaimed Devices"
          value={
            devicesFiltered && devicesFiltered.length
              ? devicesFiltered.filter((device) => !device.claimed).length
              : "N/A"
          }
        />
      </Grid>

      <Grid item xs={12} md={12}>
        <DeviceBrandDevicesTable brandID={parseInt(params.id)} />
      </Grid>
    </Grid>
  );
}

export default withAuthenticationRequired(DeviceBrand);
