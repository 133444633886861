import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { postData } from "../../api/Post";
import fetchData from "../../api/Fetch";
import updateSnackbarMessage from "../../functions/updateSnackbarMessage";
import { useSelector } from "react-redux";

export default function ApplicationAddDialog(props) {
  const { open, handleClose } = props;
  const [id, setId] = useState();
  const [eu868profile_id, setEU868Profile_id] = useState();
  const [us915profile_id, setUS915Profile_id] = useState();
  const [au915profile_id, setAU915Profile_id] = useState();

  const lns = useSelector((state) => state.lns);

  const [lns_id, setLns] = useState();
  const [device_brand_id, setDevice_brand_id] = useState("");
  const [device_brands, setDeviceBrands] = useState([]);

  useEffect(() => {
    fetchData("device/brands").then((data) => {
      if (!data) {
        return;
      }
      //sort data by description
      data.sort((a, b) => (a.description > b.description ? 1 : -1));

      setDeviceBrands(data);
    });
  }, []);

  const addApplication = () => {
    //check name is not blank and route is not blank and api_key is not blank
    if (!id) {
      updateSnackbarMessage("ID is required");
      return;
    }

    if (!lns_id) {
      updateSnackbarMessage("LNS is required");
      return;
    }

    if (!device_brand_id) {
      updateSnackbarMessage("Device Brand is required");
      return;
    }

    const data = {
      application_id: id,
      brand_id: device_brand_id,
      lns_id: lns_id,
      eu868_profile_id: eu868profile_id,
      us915_profile_id: us915profile_id,
      au915_profile_id: au915profile_id,
    };

    postData("lns/application/", data).then((data) => {
      if (!data) {
        updateSnackbarMessage("Application add error");
      }

      if (data && data.status && data.status === 200) {
        updateSnackbarMessage("Application added");
        handleClose();
      } else if (data && data.status && data.status === 409) {
        updateSnackbarMessage("Application already exists");
      } else {
        updateSnackbarMessage("Application add error" + data);
      }
    });
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Add Application</DialogTitle>
      <DialogContent>
        <FormControl fullWidth sx={{ py: 2 }}>
          <TextField
            id="id"
            label="Application ID"
            value={id}
            onChange={(e) => setId(e.target.value)}
            fullWidth
            required
          />
        </FormControl>
        <FormControl fullWidth sx={{ pb: 2 }}>
          <TextField
            id="eu868_profile_id"
            label="EU868 Profile ID"
            value={eu868profile_id}
            onChange={(e) => setEU868Profile_id(e.target.value)}
            fullWidth
            required
          />
        </FormControl>
        <FormControl fullWidth sx={{ pb: 2 }}>
          <TextField
            id="us915_profile_id"
            label="US915 Profile ID"
            value={us915profile_id}
            onChange={(e) => setUS915Profile_id(e.target.value)}
            fullWidth
            required
          />
        </FormControl>

        <FormControl fullWidth sx={{ pb: 2 }}>
          <TextField
            id="au915_profile_id"
            label="AU915 Profile ID"
            value={au915profile_id}
            onChange={(e) => setAU915Profile_id(e.target.value)}
            fullWidth
            required
          />
        </FormControl>

        <FormControl fullWidth sx={{ pb: 2 }}>
          <Autocomplete
            id="lns_id"
            options={lns}
            getOptionLabel={(option) => option.name}
            onChange={(event, newValue) => {
              setLns(newValue.id);
            }}
            renderInput={(params) => <TextField {...params} label="LNS" />}
          />
        </FormControl>
        <FormControl fullWidth sx={{ pb: 2 }}>
          <Autocomplete
            id="device_brand_id"
            options={device_brands}
            getOptionLabel={(option) => option.description}
            onChange={(event, newValue) => {
              setDevice_brand_id(newValue.id);
            }}
            renderInput={(params) => (
              <TextField {...params} label="Device Brand" />
            )}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="info" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="outlined" onClick={addApplication}>
          Add Application
        </Button>
      </DialogActions>
    </Dialog>
  );
}
