import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import deleteData from "../../api/Delete";
import updateSnackbarMessage from "../../functions/updateSnackbarMessage";

const ConfirmDeleteMember = ({
  organisation_id,
  user_id,
  open,
  handleClose,
}) => {
  const users = useSelector((state) => state.users);
  const [user, setUser] = useState(null);

  const handleDelete = () => {
    deleteData(`organisation/${organisation_id}/members/${user_id}`).then(
      (data) => {
        if (!data) {
          updateSnackbarMessage("Member delete error");
        }

        if (data && data.status && data.status === 200) {
          updateSnackbarMessage("Member deleted");

          handleClose();
        } else {
          updateSnackbarMessage("Member delete error");
        }
      }
    );
  };

  useEffect(() => {
    if (users && users.length) {
      setUser(users.filter((user) => user.id === parseInt(user_id))[0]);
    }
  }, [users, user_id]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Confirm Delete Member</DialogTitle>
      <DialogContent>
        <p>
          Are you sure you want to delete {user && user.email ? user.email : ""}
          ?
        </p>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="outlined" onClick={handleDelete} color="error">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDeleteMember;
