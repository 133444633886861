import fetchData from "../api/Fetch";

export default async function fetchActionLogHistory(deviceID) {
  try {
    const response = await fetchData(`device/${deviceID}/action_logs`);
    console.log("fetchActionLogHistory response: ", response);
    return response;
  } catch (error) {
    console.error("Error fetching action log history: ", error);
  }
}
