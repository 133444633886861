import { configureStore } from "@reduxjs/toolkit";
import devicesReducer from "./reducers/devices";
import organisationsReducer from "./reducers/organisations";
import lnsReducer from "./reducers/lns";
import usersReducer from "./reducers/users";
import snackbarReducer from "./reducers/snackbar";
import deviceBrandsReducer from "./reducers/deviceBrands";
import applicationsReducer from "./reducers/applications";

export const store = configureStore({
  reducer: {
    devices: devicesReducer,
    deviceBrands: deviceBrandsReducer,
    organisations: organisationsReducer,
    lns: lnsReducer,
    applications: applicationsReducer,
    users: usersReducer,
    snackbar: snackbarReducer,
  },
});
