import hotspot from "../hotspot.png";

export default function addImages(map) {
  //add image for location history marker
  map.loadImage(
    "http://manage.trackpac.io/device-icon.png",
    function (error, image) {
      if (error) {
        //try adding it from localhost:3000 instead
        map.loadImage(
          "http://localhost:3000/device-icon.png",
          function (error, image) {
            if (error) throw error;
          }
        );
      }
      map.addImage("marker-15", image);
    }
  );

  //add image for hotspot-icon from local source
  map.loadImage(hotspot, (error, image) => {
    if (error) throw error;
    map.addImage("hotspot-icon", image);
  });
}
