import React, { useEffect } from "react";
import DataTableBase from "./dataTableBase";
import { fetchOrganisationContacts } from "../../functions/fetchOrganisationContacts";

export default function OrganisationContacts(props) {
  const { organisation_id } = props;

  const [contacts, setContacts] = React.useState([]);

  const contactsColumns = [
    {
      name: "id",
      label: "ID",
      options: {
        sort: true,
        display: false,
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        sort: true,
        filter: true,
      },
    },
    {
      name: "contact_method",
      label: "Contact Method",
      options: {
        sort: true,
        filter: true,
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        sort: true,
        filter: true,
      },
    },
    {
      name: "phone",
      label: "Phone",
      options: {
        sort: true,
        filter: true,
      },
    },
  ];

  useEffect(() => {
    fetchOrganisationContacts(organisation_id).then((contacts) => {
      setContacts(contacts);
    });
  }, [organisation_id]);

  return (
    <DataTableBase
      title={"Organisation Contacts"}
      data={contacts}
      columns={contactsColumns}
      orderby="time_created"
      orderdirection="desc"
    />
  );
}
