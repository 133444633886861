import { withAuthenticationRequired } from "@auth0/auth0-react";
import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import OrganisationDevicesTable from "../components/Tables/OrganisationDevicesTable";
import fetchOrganisation from "../functions/fetchOrganisation";
import PageTitle from "../components/Layout/PageTitle";
import CardInfo from "../components/Cards/CardInfo";
import { Person, Router } from "@mui/icons-material";
import OrganisationMembersTable from "../components/Tables/OrganisationMembers";
import AddMemberButton from "../components/Buttons/AddMember";
import DeleteOrganisationButton from "../components/Buttons/DeleteOrganisation";
import OrganisationActions from "../components/Tables/OrganisationActions";
import OrganisationContacts from "../components/Tables/OrganisationContacts";
import OrganisationGeofences from "../components/Tables/OrganisationGeofences";

function Organisation(props) {
  const [organisation, setOrganisation] = useState(null);
  const [devicesFiltered, setDevicesFiltered] = useState(null);
  const params = useParams();

  const devices = useSelector((state) => state.devices);
  const users = useSelector((state) => state.users);

  const navigate = useNavigate();

  useEffect(() => {
    fetchOrganisation(params.id).then((organisation) => {
      setOrganisation(organisation);
    });

    //filter devices by organisation id
    if (devices) {
      setDevicesFiltered(
        devices.filter(
          (device) => device["organisation_id"] === parseInt(params.id)
        )
      );
    }
  }, [devices]);

  return (
    <Grid container spacing={1}>
      <PageTitle
        title={
          organisation && organisation.name ? organisation.name : "Organisation"
        }
      >
        <AddMemberButton
          organisation_id={params.id}
          fetchOrganisation={fetchOrganisation}
        />
        <DeleteOrganisationButton organisation_id={params.id} />
      </PageTitle>
      <Grid item xs={12} md={6}>
        <CardInfo
          avatar={<Person fontSize="large" />}
          title="Owner"
          hasEvent={true}
          event={() => {
            navigate("/user/" + organisation.owner_id);
          }}
          value={
            organisation && organisation.owner_id && users && users.length
              ? users.filter((user) => user.id === organisation.owner_id)[0]
                  .email
              : "N/A"
          }
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <CardInfo
          avatar={<Router fontSize="large" />}
          title="Total Devices"
          value={devicesFiltered ? devicesFiltered.length : "N/A"}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <OrganisationDevicesTable organisationID={params.id} />
      </Grid>
      <Grid item xs={12} md={12}>
        <OrganisationMembersTable organisation_id={params.id} />
      </Grid>
      <Grid item xs={12} md={12}>
        <OrganisationActions organisation_id={params.id} />
      </Grid>
      <Grid item xs={12} md={12}>
        <OrganisationContacts organisation_id={params.id} />
      </Grid>
      <Grid item xs={12} md={12}>
        <OrganisationGeofences organisation_id={params.id} />
      </Grid>
    </Grid>
  );
}

export default withAuthenticationRequired(Organisation);
