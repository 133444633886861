//navbar appbar

import React, { useState } from "react";
import { AppBar, Menu, MenuItem, Toolbar, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import AccountMenu from "../Menus/AccountMenu";

import { MenuRounded } from "@mui/icons-material";
import { useTheme } from "@emotion/react";
import { CONFIG } from "../../config";

export default function NavBar(props) {
  const theme = useTheme();

  const { handleCollapsed, isMobile } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [rotateChevron, setRotateChevron] = useState(false);

  const handleRotate = () => setRotateChevron(!rotateChevron);

  const handleCollapsedF = () => {
    if (localStorage.getItem("sidebarCollapsed") === "true") {
      localStorage.setItem("sidebarCollapsed", "false");
    } else {
      localStorage.setItem("sidebarCollapsed", "true");
    }
    handleRotate();
    handleCollapsed();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const rotate = rotateChevron ? "rotate(180deg)" : "rotate(0)";

  return (
    <AppBar position="static">
      <Toolbar>
        <MenuRounded
          onClick={(event) => {
            //if isMobile, open menu, else collapse sidebar
            isMobile ? setAnchorEl(event.currentTarget) : handleCollapsedF();
          }}
          style={{ transform: rotate, transition: "all 0.2s linear" }}
          sx={{
            mr: 2,
            color: theme.palette.mode === "dark" ? "#8ba1b7" : "#fff",
          }}
        />
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={!!anchorEl}
          onClose={handleClose}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuItem>
            <Link
              to="/"
              style={{ textDecoration: "none", color: "inherit" }}
              onClick={handleClose}
            >
              <Typography variant="body1">Home</Typography>
            </Link>
          </MenuItem>
          <MenuItem>
            <Link
              to="/device"
              style={{ textDecoration: "none", color: "inherit" }}
              onClick={handleClose}
            >
              <Typography variant="body1">Devices</Typography>
            </Link>
          </MenuItem>
          <MenuItem>
            <Link
              to="/user"
              style={{ textDecoration: "none", color: "inherit" }}
              onClick={handleClose}
            >
              <Typography variant="body1">Users</Typography>
            </Link>
          </MenuItem>
          <MenuItem>
            <Link
              to="/organisation"
              style={{ textDecoration: "none", color: "inherit" }}
              onClick={handleClose}
            >
              <Typography variant="body1">Organisations</Typography>
            </Link>
          </MenuItem>
          <MenuItem>
            <Link
              to="/lns"
              style={{ textDecoration: "none", color: "inherit" }}
              onClick={handleClose}
            >
              <Typography variant="body1">LNS Management</Typography>
            </Link>
          </MenuItem>
          <MenuItem>
            <Link
              to="/device/brand"
              style={{ textDecoration: "none", color: "inherit" }}
              onClick={handleClose}
            >
              <Typography variant="body1">Device Brands</Typography>
            </Link>
          </MenuItem>
        </Menu>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          <Link to="/" style={{ textDecoration: "none", color: "white" }}>
            <Typography variant="h6">{CONFIG.site_title}</Typography>
          </Link>
        </Typography>

        {
          // account menu
        }
        <AccountMenu
          themeMode={props.themeMode}
          handleThemeChange={props.handleThemeChange}
        />
      </Toolbar>
    </AppBar>
  );
}
