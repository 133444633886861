import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  TextField,
} from "@mui/material";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import deleteData from "../../api/Delete";
import updateSnackbarMessage from "../../functions/updateSnackbarMessage";
import fetchOrganisations from "../../functions/fetchOrganisations";

export default function OrganisationDeleteDialog(props) {
  const { open, handleClose, organisation_id } = props;

  const organisations = useSelector((state) => state.organisations);

  const [enteredOrganisationName, setEnteredOrganisationName] =
    React.useState("");

  const [organisation_name, setOrganisationName] = React.useState("");

  useEffect(() => {
    if (organisations.length) {
      //filter organisations by id
      let filtered = organisations.filter((organisation) => {
        // eslint-disable-next-line
        return organisation.id == parseInt(organisation_id);
      });

      //set organisation
      if (filtered && filtered.length) {
        setOrganisationName(filtered[0].name);
      }
    }
  }, [organisations, organisation_id]);

  const handleDeleteOrganisation = () => {
    deleteData(`organisation/${organisation_id}`).then((data) => {
      if (!data) {
        updateSnackbarMessage("Organisation delete error");
      }

      if (data && data.status && data.status === 200) {
        updateSnackbarMessage("Organisation deleted");
        fetchOrganisations();
        handleClose();
      } else {
        updateSnackbarMessage("Organisation delete error");
      }
    });
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Delete Organisation {organisation_name}</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ mb: 1 }}>
          Are you sure you want to delete the {organisation_name} organisation?
        </DialogContentText>
        <DialogContentText sx={{ mb: 1 }}>
          Please enter the name of the organisation to confirm.
        </DialogContentText>
        <DialogContentText sx={{ mb: 1 }}>
          {organisation_name}
        </DialogContentText>

        <FormControl fullWidth>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Organisation Name"
            type="text"
            fullWidth
            value={enteredOrganisationName}
            onChange={(event) => {
              setEnteredOrganisationName(event.target.value);
            }}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="outlined"
          color="error"
          onClick={handleDeleteOrganisation}
          disabled={organisation_name !== enteredOrganisationName}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}
