import QRCodeStyling from "qr-code-styling";
import { CONFIG } from "../config";

export default function downloadQRCode(claimCode, deviceName) {
  // generate a QR code using the claim code and download it. print claim code at the bottom of the QR code
  const qrCode = new QRCodeStyling({
    width: 300,
    height: 300,
    margin: 20,
    imageSize: 0.6,
    type: "svg",
    data: claimCode,
    image: CONFIG.logo,
    dotsOptions: {
      color: "#4267b2",
      type: "rounded",
    },
    backgroundOptions: {
      color: "#e9ebee",
    },
    imageOptions: {
      crossOrigin: "anonymous",
      imageSize: 0.6,
    },
  });

  qrCode.append(document.getElementById("canvas"));
  qrCode.download({ name: deviceName, extension: "svg" });
}
