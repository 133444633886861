import React from "react";
import UserAddDialog from "../Dialogs/userAdd";
import { Button, Tooltip } from "@mui/material";
import { PersonAdd } from "@mui/icons-material";

export default function AddUserButton() {
  const [addUserDialogOpen, setAddUserDialogOpen] = React.useState(false);

  const handleAddUserDialogOpen = () => {
    setAddUserDialogOpen(true);
  };

  const handleAddUserDialogClose = () => {
    setAddUserDialogOpen(false);
  };

  return (
    <>
      <Tooltip title="Add User">
        <Button variant="outlined" onClick={handleAddUserDialogOpen}>
          <PersonAdd />
        </Button>
      </Tooltip>
      <UserAddDialog
        open={addUserDialogOpen}
        handleClose={handleAddUserDialogClose}
      />
    </>
  );
}
