import React from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from "./containers/Dashboard";
import Users from "./containers/Users";
import User from "./containers/User";
import Devices from "./containers/Devices";
import Device from "./containers/Device";
import DeviceBrand from "./containers/DeviceBrand";
import Organisations from "./containers/Organisations";
import Organisation from "./containers/Organisation";
import Settings from "./containers/Settings";
import DeviceBrands from "./containers/DeviceBrands";
import LNSs from "./containers/LNSs";

export default function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="/user" element={<Users />} />
      <Route path="/user/:id" element={<User />} />
      <Route path="/device" element={<Devices />} />
      <Route path="/device/:id" element={<Device />} />
      <Route path="/device/brand" element={<DeviceBrands />} />
      <Route path="/device/brand/:id" element={<DeviceBrand />} />
      <Route path="/organisation" element={<Organisations />} />
      <Route path="/organisation/:id" element={<Organisation />} />
      <Route path="/lns" element={<LNSs />} />
      <Route path="/settings" element={<Settings />} />
    </Routes>
  );
}
