import fetchDevices from "./fetchDevices";
import fetchOrganisations from "./fetchOrganisations";
import fetchLnss from "./fetchLnss";
import fetchUsers from "./fetchUsers";
import fetchDeviceBrands from "./fetchDeviceBrands";
import fetchApplications from "./fetchApplications";

export default function refreshData() {
  //refresh devices
  fetchDevices();
  fetchDeviceBrands();
  fetchOrganisations();
  fetchLnss();
  fetchApplications();
  fetchUsers();
}
