import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { Typography, Grid, Card, CardHeader } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import CountCard from "../components/Cards/countCard";
import { Business, Money, People, Router } from "@mui/icons-material";
import { useTheme } from "@emotion/react";
import DevicesTable from "../components/Tables/DevicesTable";
import createTrackersMap from "../functions/createTrackersMap";
import { CONFIG } from "../config";
import { useSelector } from "react-redux";
import DevicesGraph from "../components/Graphs/DevicesGraph";
import UsersGraph from "../components/Graphs/UsersGraph";
import UsersTable from "../components/Tables/UsersTable";
import PageTitle from "../components/Layout/PageTitle";
import AddUserButton from "../components/Buttons/AddUserButton";
import AddDeviceButton from "../components/Buttons/AddDeviceButton";

function Home() {
  const { user } = useAuth0();
  const theme = useTheme();
  const mapRef = useRef(null);

  const [dailyIncome, setDailyIncome] = useState(0);
  const [weeklyIncome, setWeeklyIncome] = useState(0);
  const [monthlyIncome, setMonthlyIncome] = useState(0);

  const devices = useSelector((state) => state.devices);
  const users = useSelector((state) => state.users);
  const organisations = useSelector((state) => state.organisations);

  useEffect(() => {
    if (devices && devices.length) {
      //remove devices where last_data is null and claimed is false
      let trackers = devices.filter((device) => {
        let data = JSON.parse(device.last_data);
        if (!data) return false;
        if (!data.latitude || !data.longitude) return false;
        return true;
      });

      //eslint-disable-next-line
      let inc_service = devices.filter((device) => {
        //filter where device_brand.inc_service is true
        //check device has a device_brand
        if (!device.device_brand) return false;
        if (device.device_brand.inc_service) return true;
      });

      //eslint-disable-next-line
      let not_inc_service = devices.filter((device) => {
        //filter where device_brand.inc_service is false
        if (!device.device_brand) return false;
        if (!device.device_brand.inc_service) return true;
      });

      let inc_service_today = 0;
      let not_inc_service_today = 0;
      let inc_service_weekly = 0;
      let not_inc_service_weekly = 0;
      let inc_service_monthly = 0;
      let not_inc_service_monthly = 0;

      //check length of inc_serivce and not_inc_service and if 0, set to empty array
      if (inc_service.length) {
        // filter devices by time_created within today

        inc_service_today = inc_service.filter((device) => {
          let today = new Date();
          today.setHours(0, 0, 0, 0);
          let date = new Date(device.time_created);
          return date >= today;
        });

        inc_service_weekly = inc_service.filter((device) => {
          let date = new Date(device.time_created);
          let now = new Date();
          return (
            date.getMonth() === now.getMonth() &&
            date.getFullYear() === now.getFullYear()
          );
        });

        inc_service_monthly = inc_service.filter((device) => {
          let date = new Date(device.time_created);
          let now = new Date();
          return (
            date.getMonth() === now.getMonth() &&
            date.getFullYear() === now.getFullYear()
          );
        });
      }

      if (not_inc_service.length) {
        // filter devices by time_created within today

        not_inc_service_today = not_inc_service.filter((device) => {
          let today = new Date();
          today.setHours(0, 0, 0, 0);
          let date = new Date(device.time_created);
          return date >= today;
        });

        not_inc_service_weekly = not_inc_service.filter((device) => {
          let device_added_date = new Date(device.time_created);
          const today = new Date();
          const day = today.getDay();
          const diff = today.getDate() - day + (day === 0 ? -6 : 1); // adjust for Sunday (0) to be counted as the last day of the week

          const startOfWeek = new Date(today);
          startOfWeek.setDate(diff);
          startOfWeek.setHours(0, 0, 0, 0);

          //add 6 days to startOfWeek
          const endOfWeek = new Date(startOfWeek);
          endOfWeek.setDate(startOfWeek.getDate() + 6);

          return (
            device_added_date >= startOfWeek && device_added_date <= endOfWeek
          );
        });

        not_inc_service_monthly = not_inc_service.filter((device) => {
          let date = new Date(device.time_created);
          let now = new Date();
          return (
            date.getMonth() === now.getMonth() &&
            date.getFullYear() === now.getFullYear()
          );
        });
      }

      //check if any are empty and if so, set to empty array
      if (!inc_service_today) inc_service_today = [];
      if (!not_inc_service_today) not_inc_service_today = [];
      if (!inc_service_weekly) inc_service_weekly = [];
      if (!not_inc_service_weekly) not_inc_service_weekly = [];
      if (!inc_service_monthly) inc_service_monthly = [];
      if (!not_inc_service_monthly) not_inc_service_monthly = [];

      let totalToday = inc_service_today.length * 5;
      totalToday += not_inc_service_today.length * CONFIG.deviceCost;

      let totalWeekly = inc_service_weekly.length * 5;
      totalWeekly += not_inc_service_weekly.length * CONFIG.deviceCost;

      let totalMonthly = inc_service_monthly.length * 5;
      totalMonthly += not_inc_service_monthly.length * CONFIG.deviceCost;

      //set income
      setDailyIncome(totalToday);
      setWeeklyIncome(totalWeekly);
      setMonthlyIncome(totalMonthly);

      //create map
      createTrackersMap(mapRef, trackers);
    }

    return () => {
      //cleanup
      if (mapRef.current) {
        mapRef.current.remove();
      }
    };
  }, [devices]);

  return (
    <>
      <Grid
        container
        spacing={1}
        sx={{
          pb: 1,
        }}
      >
        <PageTitle
          title={
            "Welcome Back " +
            user.nickname.charAt(0).toUpperCase() +
            user.nickname.slice(1) +
            "!"
          }
        >
          <AddUserButton />
          <AddDeviceButton />
        </PageTitle>

        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <DevicesGraph dateLength={30} />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <UsersGraph dateLength={30} />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
          <CountCard
            title="Users"
            count={users ? users.length : "N/A"}
            icon={<People sx={{ fontSize: "2em" }} />}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
          <CountCard
            title="Devices"
            count={devices && devices.length ? devices.length : "N/A"}
            icon={<Router sx={{ fontSize: "2em" }} />}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
          <CountCard
            title="Organisations"
            count={organisations.length}
            icon={<Business sx={{ fontSize: "2em" }} />}
          />
        </Grid>

        {CONFIG.billingEnabled && (
          <>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
              <Card
                sx={{
                  "&:hover": {
                    cursor: "pointer",
                  },
                  backgroundColor:
                    theme.palette.mode === "dark" ? "#191B1F" : "#EFEFEF",
                }}
              >
                <CardHeader
                  avatar={<Money sx={{ fontSize: "2em" }} />}
                  title="Income Today"
                  titleTypographyProps={{
                    variant: "h5",
                    color: theme.palette.mode === "dark" ? "#8ba1b7" : "#000",
                    py: 2,
                    pr: 2,
                  }}
                  action={
                    <Typography variant="h5" sx={{ py: 3 }}>
                      £{dailyIncome}
                    </Typography>
                  }
                />
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
              <Card
                sx={{
                  "&:hover": {
                    cursor: "pointer",
                  },
                  backgroundColor:
                    theme.palette.mode === "dark" ? "#191B1F" : "#EFEFEF",
                }}
              >
                <CardHeader
                  avatar={<Money sx={{ fontSize: "2em" }} />}
                  title="Income This Week"
                  titleTypographyProps={{
                    variant: "h5",
                    color: theme.palette.mode === "dark" ? "#8ba1b7" : "#000",
                    py: 2,
                    pr: 2,
                  }}
                  action={
                    <Typography variant="h5" sx={{ py: 3 }}>
                      £{weeklyIncome}
                    </Typography>
                  }
                />
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
              <Card
                sx={{
                  "&:hover": {
                    cursor: "pointer",
                  },
                  backgroundColor:
                    theme.palette.mode === "dark" ? "#191B1F" : "#EFEFEF",
                }}
              >
                <CardHeader
                  avatar={<Money sx={{ fontSize: "2em" }} />}
                  title="Income This Month"
                  titleTypographyProps={{
                    variant: "h5",
                    color: theme.palette.mode === "dark" ? "#8ba1b7" : "#000",
                    py: 2,
                    pr: 2,
                  }}
                  action={
                    <Typography variant="h5" sx={{ py: 3 }}>
                      £{monthlyIncome}
                    </Typography>
                  }
                />
              </Card>
            </Grid>
          </>
        )}
      </Grid>

      <Grid
        container
        spacing={1}
        sx={{
          pb: 1,
        }}
      >
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <DevicesTable />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <UsersTable />
        </Grid>
      </Grid>
    </>
  );
}

export default withAuthenticationRequired(Home);
