import fetchData from "../api/Fetch";

export default async function getGeofences(selectedOrganisationID) {
  try {
    const apiGeofence = `organisation/${selectedOrganisationID}/geofences/`;
    const geofences = fetchData(apiGeofence);
    return geofences;
  } catch (error) {
    console.log(error);
  }

  return null;
}
