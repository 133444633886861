import { Card, CardHeader, useTheme } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const UsersGraph = ({ dateLength = 30 }) => {
  const chartRef = useRef(null);

  const theme = useTheme();

  const users = useSelector((state) => state.users);

  const [filteredUsers, setFilteredUsers] = useState([]);

  useEffect(() => {
    if (users && users.length > 0) {
      //filter users where time_created is within the last dateLength days.
      //then group by day and sum the values

      let filter = users.filter((user) => {
        let time_created = new Date(user.time_created);
        let now = new Date();
        return (
          time_created.getTime() >
          now.getTime() - 1000 * 60 * 60 * 24 * dateLength
        );
      });

      //group by day and sum the values
      filter = filter.reduce((acc, user) => {
        let time_created = new Date(user.time_created);
        let date = new Date(
          time_created.getFullYear(),
          time_created.getMonth(),
          time_created.getDate()
        );
        if (acc[date]) {
          acc[date] += 1;
        } else {
          acc[date] = 1;
        }
        return acc;
      }, {});

      //convert to array
      filter = Object.keys(filter).map((key) => {
        return {
          time_created: new Date(key).getTime(),
          count: filter[key],
        };
      });

      //sort by time_created
      filter.sort((a, b) => {
        return a.time_created - b.time_created;
      });

      setFilteredUsers(filter);
    }
  }, [users]);

  return (
    <Card
      sx={{
        backgroundColor: theme.palette.mode === "dark" ? "#191B1F" : "#EFEFEF",
      }}
    >
      <CardHeader
        title="New Signups (30 days)"
        titleTypographyProps={{
          variant: "h5",
          color: theme.palette.mode === "dark" ? "#8ba1b7" : "#000",
        }}
      />
      <ResponsiveContainer width="100%" height={300}>
        <AreaChart
          data={filteredUsers}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
          ref={chartRef}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="time_created"
            tickFormatter={(unixTime) => {
              let date = new Date(unixTime);
              return (
                date.getDate() +
                "-" +
                (date.getMonth() + 1) +
                "-" +
                date.getFullYear()
              );
            }}
          />
          <YAxis
            dataKey="count"
            tickFormatter={(count) => {
              return count.toLocaleString();
            }}
          />
          <Tooltip
            //check thememode and change background color accordingly
            contentStyle={{
              backgroundColor:
                theme.palette.mode === "dark" ? "#191B1F" : "#EFEFEF",
            }}
            //format the tooltip label to say count instead of count and the date
            labelFormatter={(count) => {
              let date = new Date(count);
              return (
                date.getDate() +
                "-" +
                (date.getMonth() + 1) +
                "-" +
                date.getFullYear()
              );
            }}
          />
          <Area
            type="monotone"
            dataKey="count"
            stroke={theme.palette.primary.main}
            fill={theme.palette.primary.main}
          />
        </AreaChart>
      </ResponsiveContainer>
    </Card>
  );
};

export default UsersGraph;
