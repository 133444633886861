import React from "react";
import DataTableBase from "./dataTableBase";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import { formatDate } from "../../functions/formatDate";

export default function OrganisationsTable() {
  const organisations = useSelector((state) => state.organisations);

  const navigate = useNavigate();

  const organisationColumns = [
    {
      name: "id",
      label: "id",
      options: {
        display: false,
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <Typography
              sx={{
                "&:hover": {
                  cursor: "pointer",
                  textDecoration: "underline",
                },
              }}
              onClick={() => {
                navigate("/organisation/" + tableMeta.rowData[0]);
              }}
            >
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: "active",
      label: "Status",
      options: {
        customBodyRender: (value) => {
          if (value) return "Active";
          return "Inactive";
        },
      },
    },
    {
      name: "time_created",
      label: "Date Added",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          if (!value) return "N/A";
          return formatDate(value);
        },
      },
    },
    {
      name: "time_updated",
      label: "Last Updated",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          if (!value) return "N/A";
          return formatDate(value);
        },
      },
    },
  ];

  return (
    <DataTableBase
      title={"Organisations"}
      data={organisations}
      columns={organisationColumns}
      orderby="time_created"
    />
  );
}
