import React, { useEffect, useState } from "react";
import DataTableBase from "../Tables/dataTableBase";
import { deviceColumns } from "./columnDefs";
import { useSelector } from "react-redux";

export default function OrganisationDevicesTable(props) {
  const devices = useSelector((state) => state.devices);

  const [filteredDevices, setFilteredDevices] = useState([]);

  //convert string to int for organisation id
  const organisationID = parseInt(props.organisationID);

  useEffect(() => {
    let mounted = true;

    if (mounted && devices.length) {
      let filtered = devices.filter((device) => {
        return device["organisation_id"] === organisationID;
      });

      setFilteredDevices(filtered);
    }
  }, [devices, props.organisationID]);

  return (
    <DataTableBase
      title={"Organisation Devices"}
      data={filteredDevices}
      columns={deviceColumns}
      orderby="time_updated"
    />
  );
}
