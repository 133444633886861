import React from "react";
import deleteData from "../../api/Delete";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import updateSnackbarMessage from "../../functions/updateSnackbarMessage";

function DeviceDeleteDialog(props) {
  const { id, open, handleClose } = props;

  const handleDelete = async () => {
    deleteData("device/" + id).then((response) => {
      if (response.status === 200) {
        window.location.href = "/device";

        handleClose();
      } else {
        updateSnackbarMessage("Error deleting device");
      }
    });
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Delete Device</DialogTitle>
      <DialogContent>
        <p>Are you sure you want to delete this device?</p>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={() => handleDelete()}
          color="error"
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeviceDeleteDialog;
