import moment from "moment";
import "moment-timezone";

const tz = moment.tz.guess();

export function formatDate(date) {
  // return the date in the local timezone using the format HH:MM DD/MM
  return moment.utc(date).tz(tz).format("HH:mm DD/MM/YYYY");
}

export function formatDateTimeOnly(date) {
  // return the date in the local timezone using the format HH:MM in 24 hour time
  return moment.utc(date).tz(tz).format("HH:mm");
}

export function formatDateDateOnly(date) {
  // return the date in the local timezone using the format HH:MM DD/MM/YYYY in 24 hour time
  return moment.utc(date).tz(tz).format("DD/MM/YYYY");
}

export function formatDateMonthYearOnly(date) {
  // return the date in the local timezone using the format DD/MM/YYYY
  return moment.utc(date).tz(tz).format("MMM YYYY");
}
