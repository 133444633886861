import React from "react";
import { Button, Tooltip } from "@mui/material";
import { PersonAdd } from "@mui/icons-material";
import AddOrganisationMembersDialog from "../Dialogs/AddOrganisationMembers";

export default function AddMemberButton(props) {
  const [addMemberDialogOpen, setAddMemberDialogOpen] = React.useState(false);

  const handleAddMemberDialogOpen = () => {
    setAddMemberDialogOpen(true);
  };

  const handleAddMemberDialogClose = () => {
    setAddMemberDialogOpen(false);
    props.fetchOrganisation(props.organisation_id);
  };

  return (
    <>
      <Tooltip title="Add Member">
        <Button variant="outlined" onClick={handleAddMemberDialogOpen}>
          <PersonAdd />
        </Button>
      </Tooltip>
      <AddOrganisationMembersDialog
        open={addMemberDialogOpen}
        handleClose={handleAddMemberDialogClose}
        organisation_id={props.organisation_id}
      />
    </>
  );
}
