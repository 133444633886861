import React, { useEffect, useState } from "react";
import NavBar from "./NavBar";
import { Alert, Container, IconButton, Snackbar } from "@mui/material";
import SidebarLayout from "./sidebar";
import { Close } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { handleSnackbarClose } from "../../functions/handleSnackbarClose";

export default function Layout(props) {
  const { data, themeMode, isMobile } = props;

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const snackbar = useSelector((state) => state.snackbar);

  useEffect(() => {
    setSnackbarOpen(snackbar.open);
    setSnackbarMessage(snackbar.message);
  }, [snackbar]);

  return (
    <>
      <NavBar
        handleThemeChange={props.handleThemeChange}
        data={data}
        themeMode={themeMode}
        handleCollapsed={props.handleCollapsed}
        isMobile={isMobile}
      />
      <div style={{ display: "flex", height: "100%", minHeight: "100vh" }}>
        {
          //if mobile, set sidebar to null
          isMobile ? null : <SidebarLayout collapsed={props.collapsed} />
        }
        <Container
          maxWidth="false"
          //set gutters to 1
          sx={{ pl: 1, pr: 1, pt: 1, pb: 1 }}
          disableGutters={true}
        >
          {props.children}
        </Container>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleSnackbarClose}
            >
              <Close fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity ? snackbar.severity : "info"}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}
