const initialState = [];

function applicationsReducer(state = initialState, action) {
  switch (action.type) {
    case "applications/init":
      return action.payload;
    case "applications/add":
      return [...state.value, action.payload];
    case "applications/remove":
      return state.value.filter((device) => device.id !== action.payload);
    case "applications/update":
      return action.payload;

    default:
      return state;
  }
}

export default applicationsReducer;
