export const CONFIG = {
  logo: "/logo.png",
  site_title: "Trackpac Management",
  Auth0Domain: "trackpac-admin.eu.auth0.com",
  Auth0ClientId: "Jts0klZ8lay4IctGPCk7OqSU3EKXvfX7",
  Auth0Audience: "https://management-api.trackpac.io",
  API_URL: "https://manage-api.trackpac.io/",
  //API_URL: "http://localhost:8000/",
  mapboxAccessToken:
    "pk.eyJ1IjoiYmZnbmVpbCIsImEiOiJjbGR2azExeW4wZ28yM29tZm4xZjJwdHFxIn0.Mdv--BkZwKSw-pdTRMQdPg",
  mapStyle: "mapbox://styles/bfgneil/cle2sqoh8003s01ogafzo3xsl",
  websiteURL: "https://manage.trackpac.io",
  billingEnabled: true,
  deviceCost: 15,
  primaryColour: "#00d09f",
  secondaryColour: "#006633",
};
