import React, { useEffect } from "react";
import DataTableBase from "./dataTableBase";
import { fetchOrganisationGeofences } from "../../functions/fetchOrganisationGeofences";

export default function OrganisationGeofences(props) {
  const { organisation_id } = props;

  const [geofences, setGeofences] = React.useState([]);

  const geofencesColumns = [
    {
      name: "id",
      label: "ID",
      options: {
        sort: true,
        display: false,
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        sort: true,
        filter: true,
      },
    },
    {
      name: "latitude",
      label: "Latitude",
      options: {
        sort: true,
        filter: true,
      },
    },
    {
      name: "longitude",
      label: "Longitude",
      options: {
        sort: true,
        filter: true,
      },
    },
    {
      name: "radius",
      label: "Radius",
      options: {
        sort: true,
        filter: true,
      },
    },
    {
      name: "start_time",
      label: "Start Time",
      options: {
        sort: true,
        filter: true,
      },
    },
    {
      name: "end_time",
      label: "End Time",
      options: {
        sort: true,
        filter: true,
      },
    },
    {
      name: "time_created",
      label: "Time Created",
      options: {
        sort: true,
        filter: true,
      },
    },
    {
      name: "time_updated",
      label: "Time Updated",
      options: {
        sort: true,
        filter: true,
      },
    },
  ];

  useEffect(() => {
    fetchOrganisationGeofences(organisation_id).then((geofences) => {
      setGeofences(geofences);
    });
  }, [organisation_id]);

  return (
    <DataTableBase
      title={"Organisation Geofences"}
      data={geofences}
      columns={geofencesColumns}
      orderby="time_created"
      orderdirection="desc"
    />
  );
}
