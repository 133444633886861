import mapboxgl from "mapbox-gl";
import { formatDate } from "./formatDate";
import formatTemperature from "./formatTemperature";

// add a pulsing marker for current location
export default function addCurrentLocation(history, mapRef, deviceData) {
  try {
    const size = 200;

    const pulsingDot = {
      width: size,
      height: size,
      data: new Uint8Array(size * size * 4),

      // When the layer is added to the map,
      // get the rendering context for the map canvas.
      onAdd: function() {
        const canvas = document.createElement("canvas");
        canvas.width = this.width;
        canvas.height = this.height;
        this.context = canvas.getContext("2d");
      },

      // Call once before every frame where the icon will be used.
      render: function() {
        const duration = 3000;
        const t = (performance.now() % duration) / duration;

        const radius = (size / 3) * 0.3;
        const outerRadius = (size / 3) * 0.7 * t + radius;
        const context = this.context;

        // Draw the outer circle.
        context.clearRect(0, 0, this.width, this.height);
        context.beginPath();
        context.arc(
          this.width / 2,
          this.height / 2,
          outerRadius,
          0,
          Math.PI * 2
        );
        context.fillStyle = `rgba(0, 208, 159, ${1 - t})`;
        context.fill();

        // Draw the inner circle.
        context.beginPath();
        context.arc(this.width / 2, this.height / 2, radius, 0, Math.PI * 2);
        context.fillStyle = "rgba(0, 208, 159)";
        context.strokeStyle = "#004d40";
        context.lineWidth = 2 + 4 * (1 - t);
        context.fill();
        context.stroke();

        // Update this image's data with data from the canvas.
        this.data = context.getImageData(0, 0, this.width, this.height).data;

        // Continuously repaint the map, resulting
        // in the smooth animation of the dot.
        mapRef.triggerRepaint();

        // Return `true` to let the map know that the image was updated.
        return true;
      },
    };

    if (history.length > 0) {
      // get last history object data and parse it
      let data = JSON.parse(history[history.length - 1].data);

      if (!mapRef.getSource("dot-point")) {
        mapRef.addSource("dot-point", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: [
              {
                type: "Feature",
                geometry: {
                  type: "Point",
                  coordinates: [data.longitude, data.latitude],
                },
                properties: {
                  title: data.geocode,
                  data: data,
                },
              },
            ],
          },
        });

        mapRef.addImage("pulsing-dot", pulsingDot, { pixelRatio: 2 });

        mapRef.addLayer({
          id: "layer-with-pulsing-dot",
          type: "symbol",
          source: "dot-point",
          layout: {
            "icon-image": "pulsing-dot",
          },
        });

        //onclick of dot-point, fly to location and show popup
        mapRef.on("click", "layer-with-pulsing-dot", function(e) {
          mapRef.flyTo({
            center: e.features[0].geometry.coordinates,
            maxDuration: 2000,
            essential: true,
            animated: false,
          });

          //parse json data
          let data = JSON.parse(e.features[0].properties.data);

          //for each key in data, write html with key and value
          let html = "";

          for (const [key, value] of Object.entries(data)) {
            //if key is latitude or longitude or batteryMv or accuracy or geocode, skip
            if (
              key === "latitude" ||
              key === "longitude" ||
              key === "batteryMv" ||
              key === "accuracy" ||
              key === "geocode" ||
              key === "wifi_macs" ||
              key === "uplink" ||
              key === "downlink" ||
              key === "dont_log_frame" ||
              key === "voltageMv"
            ) {
              continue;
            }
            if (key === "temperature") {
              if (value === null) {
                continue;
              }
              html += `<p><strong>Temperature:</strong> ${formatTemperature(
                value
              )}</p>`;
              continue;
            }

            if (key === "humidity") {
              if (value === null) {
                continue;
              }
              html += `<p><strong>Humidity:</strong> ${value}%</p>`;
              continue;
            }

            if (key === "co2") {
              if (value === null) {
                continue;
              }
              html += `<p><strong>CO2:</strong> ${value}ppm</p>`;
              continue;
            }

            if (key === "button_pressed") {
              if (value === null) {
                continue;
              }
              html += `<p><strong>Button Pressed:</strong> ${
                value === 1 ? "Yes" : "No"
              }</p>`;
              continue;
            }

            html += `<p><strong>${key}:</strong> ${value}</p>`;
          }

          new mapboxgl.Popup()
            .setLngLat(e.features[0].geometry.coordinates)
            .setHTML(
              "<h3>" +
                formatDate(e.features[0].properties.time) +
                "</h3><p><strong>Location:</strong><br /> " +
                e.features[0].properties.title +
                "</p>" +
                html
            )
            .addTo(mapRef);
        });
      } else {
        //update dot-point source
        mapRef.getSource("dot-point").setData({
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: [data.longitude, data.latitude],
              },
              properties: {
                title: data.geocode,
                data: data,
              },
            },
          ],
        });
      }
    }
  } catch (error) {
    console.log(error);
  }
}
