import React from "react";
import DataTableBase from "./dataTableBase";
import { LNSColumns } from "./columnDefs";
import { useSelector } from "react-redux";

export default function DevicesTable(props) {
  const lnss = useSelector((state) => state.lns);

  return (
    <DataTableBase
      title={"LNS's"}
      data={lnss ? lnss : []}
      columns={LNSColumns}
      orderby="time_updated"
    />
  );
}
