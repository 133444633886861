import { Box, Grid, Stack, Typography } from "@mui/material";
import React from "react";

export default function PageTitle({ title, children }) {
  return (
    <Grid item xs={12}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            flexGrow: 1,
          }}
        >
          {title}
        </Typography>
        <Stack direction="row" spacing={1}>
          {children}
        </Stack>
      </Box>
    </Grid>
  );
}
