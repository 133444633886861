import { Button, Tooltip } from "@mui/material";
import React from "react";
import DeleteUserDialog from "../Dialogs/deleteUser";
import { DeleteForever } from "@mui/icons-material";

export default function DeleteUserButton(props) {
  const { id } = props;

  const [deleteUserDialogOpen, setDeleteUserDialogOpen] = React.useState(false);

  return (
    <>
      <Tooltip title="Delete User">
        <Button
          variant="outlined"
          color="error"
          onClick={() => setDeleteUserDialogOpen(true)}
        >
          <DeleteForever />
        </Button>
      </Tooltip>
      <DeleteUserDialog
        id={id}
        open={deleteUserDialogOpen}
        onClose={() => setDeleteUserDialogOpen(false)}
      />
    </>
  );
}
