import React, { useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
  Typography,
} from "@mui/material";
import deleteData from "../../api/Delete";
import updateSnackbarMessage from "../../functions/updateSnackbarMessage";
import { useSelector } from "react-redux";

export default function DeleteUserDialog(props) {
  const { open, onClose, id } = props;

  const users = useSelector((state) => state.users);

  const [user_email, set_user_email] = React.useState("");

  const [entered_email, set_entered_email] = React.useState("");

  const handleDeleteUser = () => {
    deleteData(`user/${id}`).then((response) => {
      if (response.status === 200) {
        //response will have    msg: "User deleted"
        updateSnackbarMessage(response.data.msg || "User deleted");
      } else {
        updateSnackbarMessage("Error deleting user");
      }
      onClose();
    });
  };

  useEffect(() => {
    if (id) {
      if (users.length) {
        const user = users.find((user) => user.id === parseInt(id));
        if (user) set_user_email(user.email);
      }
    }
  }, [id, users]);

  return (
    <Dialog open={open} onClose={() => onClose()} maxWidth="sm" fullWidth>
      <DialogTitle>Delete User Account</DialogTitle>
      <DialogContent>
        <Typography sx={{ mb: 2 }}>
          Are you sure you want to delete this user? This action cannot be
          undone.
        </Typography>
        <Typography sx={{ mb: 2 }}>{user_email}</Typography>
        {
          //make the user enter the email to confirm the deletion
        }
        <Typography sx={{ mb: 2 }}>
          Please enter the email to confirm the deletion.
        </Typography>
        <FormControl fullWidth sx={{ mb: 2 }}>
          <TextField
            autoFocus
            margin="dense"
            id="email"
            label="Email Address"
            type="email"
            fullWidth
            onChange={(e) => set_entered_email(e.target.value)}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()}>Cancel</Button>
        <Button
          onClick={() => handleDeleteUser()}
          disabled={entered_email !== user_email}
          color="error"
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}
