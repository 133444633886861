import React from "react";
import OrganisationAddDialog from "../Dialogs/organisationAdd";
import { Button, Tooltip } from "@mui/material";
import { AddBusiness } from "@mui/icons-material";

export default function AddOrganisationButton(props) {
  const [addOrganisationDialogOpen, setAddOrganisationDialogOpen] =
    React.useState(false);

  const { user_id } = props;

  const handleAddOrganisationDialogOpen = () => {
    setAddOrganisationDialogOpen(true);
  };

  const handleAddOrganisationDialogClose = () => {
    setAddOrganisationDialogOpen(false);
  };

  return (
    <>
      <Tooltip title="Add Organisation">
        <Button variant="outlined" onClick={handleAddOrganisationDialogOpen}>
          <AddBusiness />
        </Button>
      </Tooltip>
      <OrganisationAddDialog
        open={addOrganisationDialogOpen}
        handleClose={handleAddOrganisationDialogClose}
        user_id={user_id}
      />
    </>
  );
}
