import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { postData } from "../../api/Post";
import updateSnackbarMessage from "../../functions/updateSnackbarMessage";

export default function LNSAddDialog(props) {
  const { open, handleClose } = props;
  const [api_route, setApi_route] = useState("");
  const [api_key, setApi_key] = useState("");
  const [name, setName] = useState("");

  const addLNS = () => {
    //check name is not blank and route is not blank and api_key is not blank
    if (!name) {
      updateSnackbarMessage("Name cannot be blank");
      return;
    }
    if (!api_route) {
      updateSnackbarMessage("API Route cannot be blank");
      return;
    }
    if (!api_key) {
      updateSnackbarMessage("API Key cannot be blank");
      return;
    }

    const data = {
      name: name,
      api_route: api_route,
      api_key: api_key,
    };

    postData("lns", data).then((data) => {
      if (!data) {
        updateSnackbarMessage("LNS add error");
      }

      if (data && data.status && data.status === 200) {
        updateSnackbarMessage("LNS added");
        handleClose();
      } else if (data && data.status && data.status === 409) {
        updateSnackbarMessage("LNS already exists");
      } else {
        updateSnackbarMessage("LNS add error");
      }
    });
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Add LNS</DialogTitle>
      <DialogContent>
        <FormControl fullWidth>
          <TextField
            id="name"
            label="Name"
            variant="outlined"
            margin="normal"
            value={name}
            required
            fullWidth
            autoFocus
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </FormControl>
        <FormControl fullWidth>
          <TextField
            id="api_route"
            label="API Route"
            variant="outlined"
            margin="normal"
            value={api_route}
            required
            fullWidth
            onChange={(e) => {
              setApi_route(e.target.value);
            }}
          />
        </FormControl>
        <FormControl fullWidth>
          <TextField
            id="api_key"
            label="API Key"
            variant="outlined"
            type="password"
            margin="normal"
            value={api_key}
            required
            fullWidth
            onChange={(e) => {
              setApi_key(e.target.value);
            }}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="info" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="outlined" onClick={addLNS}>
          Add LNS
        </Button>
      </DialogActions>
    </Dialog>
  );
}
