// dropdown for navbar for account, show icon and dropdown menu when clicked

import React, { useState } from "react";

import { IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import {
  AccountCircle,
  Brightness4,
  Brightness7,
  Search,
} from "@mui/icons-material";
import { useAuth0 } from "@auth0/auth0-react";
import SearchDialog from "../Dialogs/search";

export default function AccountMenu(props) {
  const { logout } = useAuth0();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [searchOpen, setSearchOpen] = useState(false);

  return (
    <>
      <Tooltip title="Search" placement="bottom">
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="Search"
          sx={{ mr: 2, color: "white" }}
          onClick={() => {
            setSearchOpen(true);
          }}
        >
          {
            // icon for search
          }
          <Search />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={
          props.themeMode === "dark"
            ? "Switch to Light Mode"
            : "Switch to Dark Mode"
        }
        placement="bottom"
      >
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="theme mode"
          sx={{ mr: 2, color: "white" }}
          onClick={() => props.handleThemeChange()}
        >
          {props.themeMode === "dark" ? <Brightness7 /> : <Brightness4 />}
        </IconButton>
      </Tooltip>

      <Tooltip title="Account" placement="bottom">
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="account"
          sx={{ color: "white" }}
          onClick={handleClick}
        >
          {
            // icon for account
          }
          <AccountCircle sx={{ color: "white" }} />
        </IconButton>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={!!anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem
          onClick={() => {
            logout({ returnTo: window.location.origin });
          }}
        >
          Logout
        </MenuItem>
      </Menu>

      <SearchDialog
        open={searchOpen}
        onClose={() => {
          setSearchOpen(false);
        }}
      />
    </>
  );
}
