const initialState = [];

function organisationsReducer(state = initialState, action) {
  switch (action.type) {
    case "organisations/init":
      return action.payload;

    case "organisations/add":
      return [...state.value, action.payload];
    case "organisations/remove":
      return state.value.filter((device) => device.id !== action.payload);
    case "organisations/update":
      return action.payload;

    default:
      return state;
  }
}

export default organisationsReducer;
