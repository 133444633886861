import React from "react";
import DataTableBase from "./dataTableBase";
import { userColumns } from "./columnDefs";
import { useSelector } from "react-redux";

export default function UsersTable(props) {
  const users = useSelector((state) => state.users);

  return (
    <DataTableBase
      title={"Users"}
      data={users}
      columns={userColumns}
      orderby="time_created"
    />
  );
}
