import React from "react";
import DataTableBase from "./dataTableBase";
import { ApplicationColumns } from "./columnDefs";
import { useSelector } from "react-redux";

export default function ApplicationsTable() {
  const applications = useSelector((state) => state.applications);

  return (
    <DataTableBase
      title={"Applications"}
      data={applications ? applications : []}
      columns={ApplicationColumns}
      orderby="brand"
    />
  );
}
