import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  InputLabel,
} from "@mui/material";
import React, { useState } from "react";
import { putData } from "../../api/Put";
import updateSnackbarMessage from "../../functions/updateSnackbarMessage";
import { useSelector } from "react-redux";

export default function DeviceChangeOwnership(props) {
  const { open, handleClose, device } = props;

  const [newOrganisation, setNewOrganisation] = useState(null);

  const organisations = useSelector((state) => state.organisations);

  const updateOwnership = async () => {
    let response = await putData("device/" + device.id + "/change_ownership", {
      organisation_id: newOrganisation.id,
    });

    if (response.status === 200) {
      updateSnackbarMessage("Device ownership changed successfully");
      handleClose();
    } else {
      updateSnackbarMessage("Error changing device ownership");
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Change Device Ownership</DialogTitle>
      <DialogContent>
        <InputLabel htmlFor="current-organisation">
          Current Organisation
        </InputLabel>
        <TextField
          id="current-organisation"
          value={
            device && device.organisation ? device.organisation.name : "N/A"
          }
          fullWidth
          disabled
        />
        <InputLabel htmlFor="new-organisation" sx={{ mt: 2 }}>
          New Organisation
        </InputLabel>
        <Autocomplete
          id="new-organisation"
          options={organisations}
          onChange={(event, newValue) => {
            setNewOrganisation(newValue);
          }}
          getOptionLabel={(option) => option.id + " " + option.name}
          sx={{ width: "100%", mt: 1 }}
          renderInput={(params) => <TextField {...params} fullWidth />}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="error" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="outlined"
          color="success"
          onClick={updateOwnership}
          autoFocus
        >
          Change
        </Button>
      </DialogActions>
    </Dialog>
  );
}
