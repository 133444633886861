import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import updateSnackbarMessage from "../../functions/updateSnackbarMessage";
import { useSelector } from "react-redux";
import fetchOrganisations from "../../functions/fetchOrganisations";
import { putData } from "../../api/Put";
import { fetchOrganisationMembers } from "../../functions/fetchOrganisationMembers";

export default function AddOrganisationMembersDialog(props) {
  const { open, handleClose, organisation_id } = props;

  const [organisationMembers, setOrganisationMembers] = useState([]);

  useEffect(() => {
    if (open) {
      if (!organisation_id) return;

      fetchOrganisationMembers(organisation_id).then((data) => {
        setOrganisationMembers(data);
      });
    }
  }, [open, organisation_id]);

  const updateOrganisationMembers = () => {
    putData(`organisation/${organisation_id}/members`, {
      members: organisationMembers,
    }).then((data) => {
      if (!data) {
        updateSnackbarMessage("Organisation members update error");
      }

      if (data && data.status && data.status === 200) {
        updateSnackbarMessage("Organisation members updated");
        handleClose();
      } else {
        updateSnackbarMessage("Organisation members update error");
      }
    });

    fetchOrganisations();
  };

  const users = useSelector((state) => state.users);
  const organisations = useSelector((state) => state.organisations);

  const [organisation_name, setOrganisationName] = useState("");

  useEffect(() => {
    if (organisations && organisations.length) {
      let filtered = organisations.filter((organisation) => {
        // eslint-disable-next-line
        return organisation.id == parseInt(organisation_id);
      });

      if (filtered && filtered.length) {
        setOrganisationName(filtered[0].name);
      }
    }
  }, [organisations, organisation_id]);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth={true}>
      <DialogTitle>Manage {organisation_name} Members</DialogTitle>
      <DialogContent>
        <FormControl fullWidth>
          {
            //autocomplete, when changed, update the organisationMembers state with just the id of the user, use this as value and name as display
          }
          <Autocomplete
            multiple
            id="tags-standard"
            options={users}
            getOptionLabel={(option) => option.email}
            defaultValue={organisationMembers}
            onChange={(event, value) => {
              if (value) {
                setOrganisationMembers(
                  value.map((user) => {
                    return user.id;
                  })
                );
              } else {
                setOrganisationMembers([]);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Members"
                placeholder="Members"
              />
            )}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={updateOrganisationMembers}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
}
