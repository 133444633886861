import React from "react";
import DataTableBase from "./dataTableBase";
import { deviceColumns } from "./columnDefs";
import { useSelector } from "react-redux";

export default function DeviceBrandDevicesTable(props) {
  const devices = useSelector((state) => state.devices);

  const brandID = props.brandID;

  const filteredDevices = devices.filter(
    (device) => device.device_brand_id === brandID
  );

  return (
    <DataTableBase
      title={"Devices"}
      data={filteredDevices || []}
      columns={deviceColumns}
      orderby="time_updated"
    />
  );
}
