import React from "react";
import DataTableBase from "./dataTableBase";
import { brandColumns } from "./columnDefs";
import { useSelector } from "react-redux";

export default function DeviceBrandsTable(props) {
  const deviceBrands = useSelector((state) => state.deviceBrands);

  return (
    <DataTableBase
      title={"Device Brands"}
      data={deviceBrands ? deviceBrands : []}
      columns={brandColumns}
      orderby="time_created"
    />
  );
}
