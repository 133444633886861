import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
} from "@mui/material";
import React from "react";
import { putData } from "../../api/Put";
import updateSnackbarMessage from "../../functions/updateSnackbarMessage";

export default function ChangeDeviceNameButton(props) {
  const { id, name } = props;

  const [changeNameDialogOpen, setChangeNameDialogOpen] = React.useState(false);
  const [newName, setNewName] = React.useState(name);

  const handleClose = () => {
    setChangeNameDialogOpen(false);
  };

  const updateDeviceName = () => {
    //check for name, description and owner_id
    if (!newName) {
      updateSnackbarMessage("Device name cannot be blank");
      return;
    }

    //post data
    putData(`device/${id}/name`, { name: newName }).then((data) => {
      if (!data) {
        updateSnackbarMessage("Device name update error");
      }

      if (data && data.status && data.status === 200) {
        updateSnackbarMessage("Device name updated");
        handleClose();
      } else {
        updateSnackbarMessage("Device name update error");
      }
    });
  };

  return (
    <>
      <Button
        color="primary"
        variant="outlined"
        onClick={() => {
          setChangeNameDialogOpen(true);
        }}
      >
        Change Name
      </Button>
      <Dialog
        open={changeNameDialogOpen}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle>Change {name} name</DialogTitle>
        <DialogContent>
          <FormControl fullWidth sx={{ mb: 2, mt: 2 }}>
            <TextField
              label="Old Name"
              variant="outlined"
              required
              fullWidth
              value={name}
              disabled
            />
          </FormControl>
          <FormControl fullWidth>
            <TextField
              label="New Name"
              variant="outlined"
              required
              fullWidth
              value={name}
              onChange={(e) => {
                if (!e.target.value) return;
                setNewName(e.target.value);
              }}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={updateDeviceName}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
