import fetchData from "../api/Fetch";

export const fetchOrganisationContacts = async (organisationID) => {
  if (!organisationID) return;

  let response = await fetchData(
    "organisation/" + organisationID + "/contacts"
  );
  return response;
};
