export default function formatTemperature(
  temperature,
  noDisplaySymbol = false
) {
  //check temperature unit in local storage
  let temperature_unit = localStorage.getItem("temperature_unit");

  //if temperature unit is null, set to C
  if (!temperature_unit) {
    localStorage.setItem("temperature_unit", "C");
    temperature_unit = "C";
  }

  //if temperature unit is F, convert to F
  if (temperature_unit === "F") {
    temperature = (temperature * 9) / 5 + 32;
    temperature.toFixed(0);
  }

  if (noDisplaySymbol) {
    //check temperature can run toFixed
    if (isNaN(temperature) || temperature === null) {
      return temperature;
    }
    return temperature.toFixed(0);
  }

  // to fixed to 1 decimal places and add degree symbol
  return temperature + "°" + temperature_unit;
}
