import { Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { formatDate } from "../functions/formatDate";
import DataTableBase from "../components/Tables/dataTableBase";
import createMap from "../functions/createMap";
import DeviceChangeOwnership from "../components/Dialogs/deviceChangeOwnership";
import { Router } from "@mui/icons-material";
import DeviceDeleteDialog from "../components/Dialogs/deviceDelete";
import fetchDevice from "../functions/fetchDevice";
import fetchDeviceHistory from "../functions/fetchDeviceHistory";
import isTracker from "../functions/isTracker";
import PageTitle from "../components/Layout/PageTitle";
import CardInfo from "../components/Cards/CardInfo";
import { useSelector } from "react-redux";
import ChangeDeviceNameButton from "../components/Buttons/ChangeDeviceName";
import fetchActionLogHistory from "../functions/fetchActionLogHistory";

export default function Device() {
  const params = useParams();
  const mapRef = React.useRef(null);

  const [device, setDevice] = useState(null);

  const [frameHistory, setFrameHistory] = useState(null);

  const [showChangeOwnership, setShowChangeOwnership] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const organisations = useSelector((state) => state.organisations);

  const [frameColumns, setFrameColumns] = useState([
    {
      name: "fcnt",
      label: "Frame Counter",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "data",
      label: "Data",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          //input is array
          if (!value) return "N/A";
          if (!value.length) return "N/A";
          //json decode
          let data = JSON.parse(value);

          let string = "";

          //get keys and return key: value pairs
          return Object.keys(data).map((key) => {
            //if value is empty, dont return,

            //if wifimacs, return as string
            if (key === "wifi_macs") {
              //json stringify
              let wifi_macs = JSON.stringify(data[key]);
              //replace the word "mac" with bssid
              wifi_macs = wifi_macs.replace(/mac/g, "bssid");
              return (
                //add to string
                string + key + ": " + wifi_macs + ", "
              );
            }

            if (key === "isLost") {
              return (
                string + key + ": " + (data[key] ? "true" : "false") + ", "
              );
            }

            if (key === "hotspots") {
              let hotspots = data[key];

              //for each hotspot, show name and rssi
              hotspots = hotspots.map((hotspot) => {
                return (
                  string + "Name: " + hotspot.name + " RSSI: " + hotspot.rssi
                );
              });

              return (
                //add to string
                string + key + ": " + hotspots + ", "
              );
            }

            //if dont_log_frame, return nothing
            if (key === "dont_log_frame") {
              return null;
            } else {
              return (
                //add to string
                string + key + ": " + data[key] + ", "
              );
            }
          });
        },
      },
    },
    {
      name: "time_created",
      label: "Time",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return <Typography>{formatDate(value)}</Typography>;
        },
      },
    },
  ]);

  const actionColumns = [
    {
      name: "time_created",
      label: "Time",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return <Typography>{formatDate(value)}</Typography>;
        },
      },
    },
    //message, action_id
    {
      name: "message",
      label: "Message",
      options: {
        filter: true,
        sort: true,

        customBodyRender: (value) => {
          return <Typography>{value}</Typography>;
        },
      },
    },
    {
      name: "action_id",
      label: "Action",
      options: {
        filter: true,
        sort: true,
      },
    },
  ];

  const [actionLogHistory, setActionLogHistory] = useState(null);

  useEffect(() => {
    if (!params.id) return;

    fetchDevice(params.id).then((device) => {
      setDevice(device);
    });

    fetchDeviceHistory(params.id).then((frameHistory) => {
      setFrameHistory(frameHistory);

      if (!frameHistory || !frameHistory.length) return;

      let lastFrame = frameHistory[frameHistory.length - 1];

      lastFrame = JSON.parse(lastFrame.data);

      //remove any columns where the value is null
      const columns = Object.keys(lastFrame).filter(
        (column) => lastFrame[column] !== null
      );

      const new_columns = [];

      new_columns.push(
        {
          name: "fcnt",
          label: "Frame Counter",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "time_created",
          label: "Time",
          options: {
            filter: true,
            sort: true,
            customBodyRender: (value) => {
              return <Typography>{formatDate(value)}</Typography>;
            },
          },
        }
      );

      columns.forEach((column) => {
        if (column === "dont_log_frame") return;
        if (column === "voltage_mv") return;

        new_columns.push({
          name: "data",
          label: column,
          options: {
            filter: true,
            sort: true,
            customBodyRender: (value) => {
              //json parse
              let data = JSON.parse(value);

              //if value is true or false return "Yes" or "No"
              if (data[column] === true) return "Yes";
              if (data[column] === false) return "No";

              //if wifi macs, create a string of all the macs
              if (column === "wifi_macs") {
                //for each wifi mac, return the mac and rssi in a comma separated string
                let string = "";

                if (!data[column]) return "N/A";

                data[column].forEach((wifi_mac) => {
                  string += wifi_mac.mac + " " + wifi_mac.rssi + " RSSI, ";
                });

                return string;
              }

              //data[column] may be an array or object, so json stringify if it is
              if (typeof data[column] === "object") {
                return JSON.stringify(data[column]);
              }

              //return the data
              return data[column];
            },
          },
        });
      });

      //set the columns
      setFrameColumns(new_columns);
    });

    fetchActionLogHistory(params.id).then((actionLogHistory) => {
      setActionLogHistory(actionLogHistory);
    });

    return () => {
      //cleanup
      if (mapRef.current) {
        mapRef.current = null;
      }
    };
  }, [params.id]);

  useEffect(() => {
    if (!device || !device.device_type) return;

    if (!frameHistory || !frameHistory.length) return;

    if (isTracker(device.device_type)) {
      createMap(mapRef, frameHistory, device);
    }
  }, [device, frameHistory]);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <PageTitle title={device && device.name ? device.name : "N/A"}>
            <Button
              variant="outlined"
              onClick={() => {
                setShowChangeOwnership(true);
              }}
            >
              Change Ownership
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                setOpenDeleteDialog(true);
              }}
            >
              Delete Device
            </Button>
            <ChangeDeviceNameButton
              id={params.id}
              name={device && device.name ? device.name : "N/A"}
            />
          </PageTitle>
        </Grid>
        <Grid item xs={12} md={3}>
          <CardInfo
            avatar={<Router sx={{ fontSize: "2em" }} />}
            title="Last Seen"
            value={
              device && device.time_updated
                ? formatDate(device.time_updated)
                : "N/A"
            }
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <CardInfo
            avatar={<Router sx={{ fontSize: "2em" }} />}
            title="Organisation"
            value={
              device && device.organisation_id //lookup organisation name
                ? organisations && organisations.length
                  ? organisations.filter(
                      (organisation) =>
                        organisation.id === device.organisation_id
                    )[0].name
                  : "N/A"
                : "N/A"
            }
            hasEvent
            event={() => {
              window.location.href = "/organisation/" + device.organisation_id;
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <CardInfo
            avatar={<Router sx={{ fontSize: "2em" }} />}
            title="Claimed?"
            value={device && device.claimed ? "True" : "False"}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <CardInfo
            avatar={<Router sx={{ fontSize: "2em" }} />}
            title="DevEUI"
            value={device && device.dev_eui ? device.dev_eui : "N/A"}
          />
        </Grid>
        {device && device.device_type && isTracker(device.device_type) ? (
          <Grid item xs={12}>
            <div id="map" />
          </Grid>
        ) : null}
        <Grid item xs={12}>
          <DataTableBase
            title="Frames"
            columns={frameColumns}
            data={frameHistory && frameHistory.length ? frameHistory : []}
            orderby="time_created"
          />
        </Grid>
      </Grid>

      <Grid item xs={12} sx={{ mt: 1 }}>
        <DataTableBase
          title="Action Alerts"
          columns={actionColumns}
          data={
            actionLogHistory && actionLogHistory.length ? actionLogHistory : []
          }
          orderby="time_created"
        />
      </Grid>

      <DeviceChangeOwnership
        open={showChangeOwnership}
        handleClose={() => {
          setShowChangeOwnership(false);
          fetchDevice();
        }}
        device={device}
      />

      <DeviceDeleteDialog
        id={params.id}
        open={openDeleteDialog}
        handleClose={() => {
          setOpenDeleteDialog(false);
        }}
      />
    </>
  );
}
