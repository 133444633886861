import { CONFIG } from "../config";
import addPolyline from "./addPolyline";
import addHistoryMarkers from "./addHistoryMarkers";
import addCurrentLocation from "./addCurrentLocation";
import addImages from "./mapAddImages";
import { minMaxLngLat } from "./minMaxLngLat";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import addGeofences from "./addGeofences";

//mapbox access token
mapboxgl.accessToken = CONFIG.mapboxAccessToken;

// create map function
export default async function createMap(mapRef, history, deviceData) {
  try {
    if (!mapRef.current === null) return;

    let initialLongLat = [0, 0];

    if (history && history.length > 0) {
      //parse the last data object in history
      let data = JSON.parse(history[history.length - 1].data);
      initialLongLat = [data.longitude, data.latitude];
    }

    mapRef.current = new mapboxgl.Map({
      container: "map",
      style: CONFIG.mapStyle,
      center: initialLongLat,
      zoom: 16,
    });

    addImages(mapRef.current);

    // check history is not empty and length is greater than 0
    if (history && history.length === 0) {
      // add a popup and open it by default
      new mapboxgl.Popup()
        .setLngLat([0, 0])
        .setHTML(
          "<div style='color: black;'>" +
            "<h3>No Location History</h3><p>No location history for this device for the selected dates</p></div>"
        )
        .addTo(mapRef.current);
      return;
    } else {
      mapRef.current.on("load", async function () {
        if (history && history.length > 0) {
          addPolyline(history, mapRef.current);
          addHistoryMarkers(history, mapRef.current);
          addCurrentLocation(history, mapRef.current, deviceData);
          addGeofences(deviceData.organisation, mapRef.current);

          //create bounds of history
          let bounds = new mapboxgl.LngLatBounds();

          //add each point to the bounds
          history.forEach((item) => {
            let data = JSON.parse(item.data);
            //check latitude and longitude are not 0
            if (data.latitude !== 0 && data.longitude !== 0) {
              //check if not empty string
              if (data.latitude !== "" && data.longitude !== "") {
                //check not null
                if (data.latitude !== null && data.longitude !== null) {
                  bounds.extend(minMaxLngLat([data.longitude, data.latitude]));
                }
              }
            }
          });

          //check if bounds are empty
          if (bounds.isEmpty()) {
            // add a popup and open it by default
            new mapboxgl.Popup()
              .setLngLat([0, 0])
              .setHTML(
                "<div style='color: black;'>" +
                  "<h3 style='color: black;'>No Location History</h3><p style='color: black;'>No location history for this device for the selected dates</p></div>"
              )
              .addTo(mapRef.current);
          } else {
            //set the map bounds
            mapRef.current.fitBounds(bounds, {
              padding: 50,
              maxZoom: 16,
              duration: 0,
            });
          }
        } else {
          // add a popup and open it by default
          new mapboxgl.Popup()
            .setLngLat([0, 0])
            .setHTML(
              "<div style='color: black;'>" +
                "<h3 style='color: black;'>No Location History</h3><p style='color: black;'>No location history for this device for the selected dates</p></div>"
            )
            .addTo(mapRef.current);
        }
      });
    }
  } catch (error) {
    console.log(error);
  }
}
