import React, { useEffect, useMemo, useRef, useState } from "react";
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import { BrowserRouter } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import "./App.css";
import AppRoutes from "./routes";
import Layout from "./components/Layout/Layout";
import { ProSidebarProvider } from "react-pro-sidebar";
import refreshData from "./functions/refreshData";
import { CONFIG } from "./config";

function App() {
  const interval = useRef(null);

  const { user } = useAuth0();

  const [themeMode, setThemeMode] = useState(
    localStorage.getItem("theme") || CONFIG.defaultTheme
  );

  const [isMobile, setIsMobile] = useState(window.innerWidth < 900);

  const { getAccessTokenSilently } = useAuth0();

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: themeMode === "dark" ? "dark" : "light",
          gradient:
            themeMode === "dark"
              ? "linear-gradient( to bottom, #191B1F 0%, #191B1F 550px, #25282D 550px, #25282D 150%)"
              : "linear-gradient( to bottom, " +
                CONFIG.primaryColour +
                " 0%, " +
                CONFIG.primaryColour +
                " 550px, #EFEFEF 550px , #EFEFEF 150%)",
          primary: {
            main: CONFIG.primaryColour,
          },
          secondary: {
            main: CONFIG.secondaryColour,
          },
        },
        typography: {
          fontFamily: "Plus Jakarta Sans",
        },
      }),
    [themeMode]
  );

  const handleThemeChange = (mode = null) => {
    if (mode !== null) {
      setThemeMode(mode);
      localStorage.setItem("theme", mode);
      return;
    }

    if (localStorage.getItem("theme") === null) {
      setThemeMode(CONFIG.defaultTheme === "light" ? "dark" : "light");
      localStorage.setItem(
        "theme",
        CONFIG.defaultTheme === "light" ? "dark" : "light"
      );
    } else if (localStorage.getItem("theme") === "light") {
      setThemeMode("dark");
      localStorage.setItem("theme", "dark");
    } else {
      setThemeMode("light");
      localStorage.setItem("theme", "light");
    }
  };

  const [collapsed, setCollapsed] = useState(
    localStorage.getItem("sidebarCollapsed") === "true" ? true : false
  );

  const handleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const fetchTokenAndRefreshData = async () => {
    try {
      let accessToken = await getAccessTokenSilently();

      localStorage.setItem("access_token", accessToken);
      refreshData();
    } catch (e) {
      console.log(e.message);
    }
  };

  // Use useEffect to run side effects after render
  useEffect(() => {
    if (user) {
      // fetch user access token and store it in local storage
      fetchTokenAndRefreshData();

      // Set up an interval to refresh the data every 30 seconds
      interval.current = setInterval(() => {
        refreshData();
      }, 30000);

      //event listener to detect if mobile
      window.addEventListener("resize", () => {
        if (window.innerWidth < 900) {
          setIsMobile(true);
        } else {
          setIsMobile(false);
        }
      });
    }

    // Clean up function to run when the component unmounts
    return () => {
      clearInterval(interval.current);
    };
  }, [user]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <ProSidebarProvider>
          <Layout
            handleThemeChange={handleThemeChange}
            themeMode={themeMode}
            handleCollapsed={handleCollapsed}
            collapsed={collapsed}
            isMobile={isMobile}
          >
            <AppRoutes />
          </Layout>
        </ProSidebarProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
